import { useLayoutEffect } from "react";
import Layout from "../../components/layout/Layout";
import Hero from "../../components/layout/page/Hero";
import ReposListPageLayout from "../../components/layout/page/ReposListPageLayout";
import { reposAccessApi } from "../../client/edbApiClient";
import { useSelector, useDispatch } from "react-redux";
import { reposAccessReceived } from "../../store/reposAccessSlice";
import { ReposAccess } from "../../resources/Types";
import Repos2PackagesUrls from "../../components/repos/Repos2PackagesUrls";
import Repos2KubernetesDocLinks from "../../components/repos/Repos2KubernetesDocLinks";

interface reposAccessState {
  reposAccess: ReposAccess;
}

const Repos2ListPage = () => {
  const reposAccess = useSelector(
    (state: reposAccessState) => state.reposAccess
  );
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    fetchReposAccess();
  }, [dispatch]);

  const fetchReposAccess = async () => {
    return await reposAccessApi().then((res) => {
      dispatch(reposAccessReceived(res));
    });
  };

  return (
    <Layout>
      <Hero title="EDB repositories for Linux" class="repos" />
      <ReposListPageLayout page="repos" repoType="cloudsmith" />
      <Repos2PackagesUrls />
      <Repos2KubernetesDocLinks />
    </Layout>
  );
};

export default Repos2ListPage;
