import { ProductsApi } from "./edbProductsApi";
import { CloudsmithApi } from "./edbCloudsmithApi";
import { HeaderFooterApi } from "./edbHeaderFooterApi";
import { OktaUserApi } from "./edbOktaUser";
import { ReposAccessApi } from "./edbReposAccess";
import { productRegistryApi } from "./edbProductRegistryApi";
import { DownloadInstallerApi } from "./edbDownloadInstallerApi";
import { DrupalApi } from "./edbDrupalApi";
import { MarketoApi } from "./edbMarketo"
import { marketoData } from "../resources/Types"
import config from "../config"

export const drupalProductApi = () => {
  const productData = ProductsApi.getProducts()
  .then((data) => {
    return data;
  })
  .catch((e) => {
    console.log(e);
  })

  return productData;
}

export const dwProductApi = () => {
  const productData = ProductsApi.getProductsDW()
  .then((data) => {
    return data;
  })
  .catch((e) => {
    console.log(e);
  })

  return productData;
}

export const dwProductMetaApi = () => {
  const productData = ProductsApi.getProductsMetaListDW()
  .then((data) => {
    return data;
  })
  .catch((e) => {
    console.log(e);
  })

  return productData;
}

export const dwProductUrlApi = (uuid: string) => {
  const productData = ProductsApi.getProductUrlDW(uuid)
  .then((data) => {
    return data;
  })
  .catch((e) => {
    console.log(e);
  })

  return productData;
}

export const cloudsmithTokenApi = () => {
  const token = CloudsmithApi.getCloudsmithToken()
  .then((data) => {
    return data;
  })
  .catch((e) => {
    console.log(e);
  })

  return token;
}

export const headerFooterApi = () => {
  const headerFooterDom = HeaderFooterApi.getHeaderFooter()
  return headerFooterDom;
}
export const edbEmployeecloudsmithTokenApi = () => {
  const token = CloudsmithApi.getEdbEmployeeCloudsmithToken()
  .then((data) => {
    return data;
  })
  .catch((e) => {
    console.log(e);
  })
  return token;
}

export const oktaUserApi = () => {
  const user = OktaUserApi.getOktaUser()
  .then((data) => {
    return data;
  })
  .catch((e) => {
    console.log(e);
  })

  return user;
}

export const oktaUserUsername = () => {
  const user = OktaUserApi.getOktaUserUsername()
  .then((data) => {
    return data;
  })
  .catch((e) => {
    console.log(e);
  })

  return user;
}

export const reposAccessApi = () => {
  const access = ReposAccessApi.getRepoAccess()
  .then((data) => {
    return data;
  })
  .catch((e) => {
    console.log(e);
  })

  return access;
}

export const operatingSystemsApi = () => {
  const os = productRegistryApi.getOperatingSystems()
  .then((data) => {
    return data;
  })
  .catch((e) => {
    console.log(e);
  })

  return os;
}

export const productsByOsApi = (id: string) => {
  const products = productRegistryApi.getProductsByOs(id)
  .then((data) => {
    return data;
  })
  .catch((e) => {
    console.log(e);
  })

  return products;
}

export const instructionsReplacementsApi = (packageName: string, distro: string, codeName: string) => {
  const instructions = productRegistryApi.getInstructions(packageName, distro, codeName)
  .then((data) => {
    return data;
  })
  .catch((e) => {
    console.log(e);
  })

  return instructions;
}

export const instructionsForLegacyApi = (versionId: number, osId: string) => {
  const instructions = productRegistryApi.getInstructionsForLegacy(versionId, osId)
  .then((data) => {
    return data;
  })
  .catch((e) => {
    console.log(e);
  })

  return instructions;
}

export const downloadInstallerApi = (uuid: string, campaignId: string) => {
  const installer = DownloadInstallerApi.getDownloadInstaller(uuid, campaignId)
  .then((data) => {
    return data;
  })
  .catch((e) => {
    console.log(e);
  })

  return installer;
}

export const communityProductApi = (uuid: string, token: string): any => {
  const product = DrupalApi.getCommunityProduct(uuid, token)
  .then((data) => {
    return data;
  })
  .catch((e) => {
    console.log(e)
  })
  
  return product
}

export const getDrupalToken = async () => {


  const formData = new FormData()

  formData.append("grant_type", "password");
  formData.append("scope", "content_api");

  const clientId = typeof config.drupal.client_id === "string" ? config.drupal.client_id : "";
  const clientSecret = typeof config.drupal.client_secret === "string" ? config.drupal.client_secret : "";
  const username = typeof config.drupal.username === "string" ? config.drupal.username : "";
  const password = typeof config.drupal.password === "string" ? config.drupal.password : "";
  
  formData.append("client_id", clientId);
  formData.append("client_secret", clientSecret);
  formData.append("username", username);
  formData.append("password", password);

  const response = await DrupalApi.getOAuthToken(formData)
  .then((data) => {
    return data;
  })
  .catch((e) => {
    console.log(e)
  })

  return response.access_token
};

export const marketoPostData = async (data: marketoData) => {
  const product = MarketoApi.postToMarketo(data)
  .then((data) => {
    return data;
  })
  .catch((e) => {
    console.log(e)
  })

  return product
}