import React, { useRef} from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import SyntaxHighlighter from "react-syntax-highlighter";
import { obsidian } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ToolTipOverlay from "../ToolTipOverlay";

interface legacyInstructionsState {
  legacyInstructions: {
    instructions: {
      data?: []
    }
  };
}

interface legacyInstrObj {
  command_line?: string;
  distributable_id?: number;
  package_name?: string;
  post_install_commands?: string;
  pre_install_commands?: string;
  software_name?: string;
}

const InstructionsLegacy = () => {
  const legacyInstructions = useSelector((state: legacyInstructionsState) => state.legacyInstructions);
  const copyRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const copiedRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  let commandLine = '';
  let packageName = '';
  let preInstallCommands = '';
  let postInstallCommands = '';

  if (typeof legacyInstructions === "object" && 
  'instructions' in legacyInstructions && 
  typeof legacyInstructions.instructions === "object" && 
  Object.keys(legacyInstructions.instructions).length > 0) {
    if ('data' in legacyInstructions.instructions && 
    Array.isArray(legacyInstructions.instructions.data) && legacyInstructions.instructions.data.length > 0) {
      legacyInstructions.instructions.data.map((dataObj: legacyInstrObj) => {
        commandLine = typeof dataObj.command_line === "string" ? dataObj.command_line : "";
        packageName = typeof dataObj.package_name === "string" ? dataObj.package_name : "";
        preInstallCommands = typeof dataObj.pre_install_commands === "string" ? dataObj.pre_install_commands : "";
        postInstallCommands = typeof dataObj.post_install_commands === "string" ? dataObj.post_install_commands : "";
      });
    }
  }

  const legacyCommands = `${preInstallCommands} \n\n${commandLine} ${packageName}`

  return (
    <div className="repos-instructions">
      <div className="d-flex justify-content-between">
        <div>
          Copy and paste individual commands or the entire script below to install and configure your software:
        </div>
        <div>
          <Link
            to={''}
            className={'text-decoration-none copy-script me-3'}
            onClick={(event) => {
              event.preventDefault();
              navigator.clipboard.writeText(
                legacyCommands
              )
              copyRef.current.className = "d-none";
              copiedRef.current.className = "show";
              setTimeout(() => {
                copyRef.current.className = "show";
                copiedRef.current.className = "d-none"
              }, 3000)
            }}
          >
            <span
              ref={copyRef}
            >
              <ToolTipOverlay
                keyId="instructions-copy"
                placement="left"
                tooltipLabel="Copy Instructions"
                tooltipClass=""
                component="CopyIcon"
              />
            </span>
            <span
              ref={copiedRef}
              className="d-none"
            >
              <ToolTipOverlay
                keyId="instructions-copied"
                placement="left"
                tooltipLabel="Copied Instructions"
                tooltipClass=""
                component="CopiedIcon"
              />
            </span>
          </Link>
        </div>
      </div>
      <SyntaxHighlighter language="bash" style={obsidian}>
        {legacyCommands}
      </SyntaxHighlighter>
    </div>
  )
}

export default InstructionsLegacy;