import axios, { AxiosResponse } from "axios";
import config from "../config";
import { marketoData } from "../resources/Types"

const edbApiInstance = axios.create({
  baseURL: config.edbApi.baseUrl,
  timeout: 5000,
});

const responseBody = (response: AxiosResponse) => response.data

const postToMarketo = {
  post: (url: string, data: marketoData) => edbApiInstance.post<string>(url, JSON.stringify(data), {
    headers: {
      'Content-type': 'application/json'
    }
  }).then(responseBody)
}

export const MarketoApi = {
  postToMarketo : ( data: marketoData ) : Promise<string> => postToMarketo.post(`/api/marketo/send-lead`, data)
 }