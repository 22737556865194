import React from "react";
import config from "../../config";

const AccountExpired = () => {
  return (
    <div className="alert alert-info expired-alert" role="alert">
      <span className="fw-bold me-2">
        Your EDB Repos access has expired.
      </span>
      If you have any questions, please
      <a href={`${config.edbWeb.baseUrl}/contact/account`} className="ms-1 text-decoration-none">
        contact us.
      </a>
    </div>
  )
}

export default AccountExpired;