import { useEffect } from "react";

const AccountsLoginRedirect = () => {
  useEffect(() => {
    const protocol = window.location.protocol;
    const domain = window.location.hostname;
    let port = '';
    if (location.port) {
      port = `:${location.port}`;
    }
    const url = `${protocol}${domain}${port}/accounts/login`;
    window.location.replace(url);
  }, [1])

  return (
    <></>
  )
}

export default AccountsLoginRedirect;