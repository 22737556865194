import React, { useRef } from "react";
import config from "../../config";

const CheckingForAccess = () => {
  const checkingAccessRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  return (
    <div className="card p-5" ref={checkingAccessRef}>
      <div className="d-flex">
        <div className="spinner-border text-secondary mx-2" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <h4>Checking For Access</h4>
      </div>
      <p className="pt-2">
        Please wait a moment as we check your level of access.  
        If the page fails to load, 
        <a 
          href={`https://info.enterprisedb.com/EDB-Contact-Us.html`} 
          className="ms-1 text-decoration-none"
        >contact us</a> for help.
      </p>
    </div>
  )
}

export default CheckingForAccess;