import { Fragment, useEffect, useRef } from "react";
import ProductsPlacholder from "../../resources/ProductsPlaceholder";
import PlatformCompatibilityPlaceholder from "../../resources/PlatformCompatibilityPlaceholder";
import AnchorLink from "../AnchorLink";
import { ChevronDown, ChevronRight } from "../icons/chevrons";
import config from "../../config";

interface CompatibilityTableProps {
  products: string[];
}

interface Product {
  product: string;
}

const CompatibilityTable = (props: CompatibilityTableProps) => {
  const productDetailsRef = useRef(new Array(props.products.length));
  const chevronRightRef = useRef(new Array(props.products.length));
  const chevronDownRef = useRef(new Array(props.products.length));
  const anchorRef = useRef(new Array(props.products.length));
  const cardRef = useRef(new Array(props.products.length));
  const products = props.products;
  
  useEffect(() => {
    setTimeout(() => {
      if (window.location.hash) {
        cardRef.current.map((v: HTMLDivElement) => {
          if (window.location.hash === `#${v.id}`) {
            v.scrollIntoView();
            v.focus();
          }
        });
      }
    }, 2000);
  }, [setTimeout])

  const clickHandle = (index: number) => {
    // Toggle Product Details Ref div.
    if (productDetailsRef.current[index].className === "show") {
      // Hide ChevronDown.
      chevronDownRef.current[index].className = "d-none";
      // Show ChevronRight.
      chevronRightRef.current[index].className = "me-2 show";

      // Hide details.
      productDetailsRef.current[index].className = "d-none";
    }
    else {
      // Hide CheveronRight.
      chevronRightRef.current[index].className = "d-none";
      // Show ChevronDown.
      chevronDownRef.current[index].className = "me-2 show";

      productDetailsRef.current[index].className = "show";
    }
    // End Toggle of Product Details Ref div.
  }

  const handleMouseOver = (index: number) => {
    anchorRef.current[index].className = "visible position-relative anchor-link";
  }

  const handleMouseOut = (index: number) => {
    anchorRef.current[index].className = "invisible position-relative anchor-link";
  }

  const downloadsUrl = '/software-downloads-postgres'

  const productsDom = products.map((product, index) => {
    const ProductDetails = ProductsPlacholder[product];
    const searchRegExp = /\s/g;
    const urlAnchorTitle = `#${ProductDetails.title.toLowerCase().replace(searchRegExp, "-")}`;
    // https://bobbyhadz.com/blog/typescript-element-implicitly-has-any-type-expression
    const ProductCompatibility = PlatformCompatibilityPlaceholder[product as keyof typeof PlatformCompatibilityPlaceholder];
    const versions = ProductCompatibility.versions;
    const platforms = ProductCompatibility.platforms;

    const subTitle = product === "pgk8s" || product === "cnpg" ?
    "Currently Supported Kubernetes Distributions" : "Currently Supported CPU Architecture and OS";

    let downloadLink = `${config.edbWeb.baseUrl}${downloadsUrl}${urlAnchorTitle}`;

    
    const hideArray = ['bdr', 'harp', 'liveCompare', 'patroni']
    let showDownloadLink = true;
    if (hideArray.includes(product)) {
      showDownloadLink = false;
    }
    
    if (product === 'pgk8s') {
      downloadLink = 'https://quay.io/organization/enterprisedb';
    }
    if (product === 'cnpg') {
      downloadLink = 'https://ghcr.io/cloudnative-pg/cloudnative-pg';
    }
    if (product === 'tpa') {
      downloadLink = `${config.edbWeb.baseUrl}${downloadsUrl}#trusted-postgres-architect`;
    }

    const Footnote = (props: Product) => {
      let footnote: JSX.Element;
      switch(props.product) {
        case 'tpa':
          footnote = <div className="text-muted">
            <p>
              * TPA previously used date versioning, but this is no longer the case, so 24.0 will only be released in the event of breaking changes
            </p>
          </div>
        break;
        case 'pgk8s':
          footnote = <div className="text-muted">
            <p>
              * EDB Postgres for Kubernetes is supported on Kubernetes (k8s)
                  distributions using versions that have not yet reached End of
                  Life as <a href="https://kubernetes.io/releases/" target="__blank">
                  defined by the k8s community
                  </a>
                  . EDB tests the latest version of EDB Postgres for Kubernetes on
                self-managed k8s clusters and the managed services from
                Microsoft (AKS), Amazon (EKS), Google (GKE) and Rancher (RKE)
                for all supported k8s versions at time of release.
            </p>
            <p>
              ** EDB Postgres for Kubernetes is supported on Red Hat OpenShift
                versions that have not yet reached end of
                Maintenance Support or Extended Support (if applicable) as{" "}
                <a href="https://access.redhat.com/support/policy/updates/openshift#dates" target="__blank">
                  defined by Red Hat
                </a>
                . EDB tests the latest version of EDB Postgres for Kubernetes
                for all supported OpenShift versions at time of release.
            </p>
            <hr className="my-4" />
            <p className="text-uppercase text-dark fs-6" style={{ fontSize: "0.9rem" }}>
              Container images
            </p>
            <p>
              Database images can be downloaded from the EDB repository on{" "}
              <a href="https://quay.io/organization/enterprisedb">
                quay.io
              </a>{" "}
                . 
                {" "}<a href="/resources/platform-compatibility#pg">PostgreSQL</a>{" "}
                and 
                {" "}<a href="/resources/platform-compatibility#epas">Postgres Advanced Server</a>{" "} 
                support dates are
                defined above.
            </p>
          </div>
        break;
        case 'cnpg':
          footnote = <div className="text-muted">
              <p>
                * CloudNativePG is supported on Kubernetes (k8s) distributions
                  using versions that have not yet reached End of Life as{" "}
                  <a href="https://kubernetes.io/releases/" target="__blank">
                  defined by the k8s community
                  </a>
                  . EDB tests the latest version of CloudNativePG on self-managed
                k8s clusters and the managed services from Microsoft (AKS),
                Amazon (EKS), Google (GKE) and Rancher for all supported k8s
                versions at time of release.
              </p>
              <hr className="my-4" />
              <p className="text-uppercase text-dark fs-6" style={{ fontSize: "0.9rem" }}>
                Container images
              </p>
              <p className="text-dark">
                Database images can be downloaded from the EDB repository on{" "} 
                <a href="https://quay.io/organization/enterprisedb">quay.io</a>{" "}
                or{" "}
                <a href="https://ghcr.io/cloudnative-pg/postgresql">ghcr.io</a>
                . 
                {" "}<a href="/resources/platform-compatibility#pg">PostgreSQL</a>{" "}
                support dates are defined above.
              </p>
            </div>
          break;
        case 'bdr':
          footnote = <div className="text-muted">
              <p>
                * EDB Postgres Distributed 4.x use High Availability Routing for Postgres (HARP) for cluster management and the support information for HARP is the same as PGD 4.x
              </p>
            </div>
          break;
        case 'efm':
          footnote = <div className="text-muted">
              <p>
                Failover Manager is supported for 
                {" "}<a href="/resources/platform-compatibility#pg">PostgreSQL</a>{" "}
                and EDB 
                {" "}<a href="/resources/platform-compatibility#epas">Postgres Advanced Server</a>{" "}
                according to support dates defined above.
              </p>
            </div>
          break;
        default:
          footnote = <></>
      }

      return (
        footnote
      )
    }
 
    const SupportSection = (os: any) => (
      <>
      {os.os.map((o: any, index: string) => (
        <tr key={`${index}${o.name}`}>
          <td className="column-1">
            <span className="fw-bold cell-indent">
              {o.name}
            </span>
          </td>
          {o.support.map((s: string, index: string) => (
            <td key={`${s}${index}`}>
              {s}
            </td>
          ))}
        </tr>
      ))}
      </>
    )

    const productTable = <table className="table platform-compatiblity-table">
      <thead>
        <tr className="row-1">
          <th scope="col" className="column-1">
            {showDownloadLink && (<span>
              <a href={downloadLink} className="text-decoration-none fw-normal">
                Downloads →
              </a>
            </span>)}
            <span className="ms-4">
              <a href={`${config.edbWeb.baseUrl}${ProductDetails.urlDocs}`} className="text-decoration-none fw-normal">
                Docs →
              </a>
            </span>
          </th>
          {versions.map((version, index) => (
            <th key={`${index}${version.name}`} scope="col" className="version-col">
              {version.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="column-1">
            <span className="fw-bold">
              General availability
            </span>
          </td>
          {versions.map((version) => (
            <td key={version.generalAvailability}>
              {version.generalAvailability}
            </td>
          ))}
        </tr>
        <tr>
          <td className="column-1">
            <span className="fw-bold">
              Standard support end date
            </span>
          </td>
          {versions.map((version) => (
            <td key={version.supportEndDate}>
              {version.supportEndDate}
            </td>
          ))}
        </tr>
        <div>
          <div className="row-no-border cell-section column-1">
            <span className="fw-bold">
              {subTitle}
            </span>
          </div>
        </div>
      {platforms.map((platform, index) => (
        <Fragment key={platform.name+index}>
          <tr>
            <td className="row-no-border column-1">
              <span className="fw-bold">
                {platform.name}
              </span>
            </td>
          </tr>
          <SupportSection os={platform.os} />
        </Fragment>
      ))}
      </tbody>
    </table>

    const anchorLinkStyles = {
      position: 'absolute',
      left: '-25px',
      top: '0px',
      paddingLeft: '3px'
    }
    let cardId = product;
    if (product === "tpa") {    
      cardId = "trusted-postgres-architect"
    }

      return (
        <div 
          key={product} className="card shadow mb-4 bg-body rounded card-pad"
          onMouseOver={() => handleMouseOver(index)}
          onMouseOut={() => handleMouseOut(index)}
          id={cardId}
          ref={(element) => cardRef.current[index] = element}
        >
          <div className="card-body">
            <AnchorLink 
              url={`${config.edbWeb.baseUrl}/resources/platform-compatibility`} 
              style={anchorLinkStyles}
              ref={(element) => anchorRef.current[index] = element} 
              anchorType={cardId}
              path={'/resources/platform-compatibility'}
            />
            <button onClick={() => clickHandle(index)} className="btn btn-link text-decoration-none text-black d-flex">
              <span className="me-2" ref={(element) => chevronRightRef.current[index] = element}>
                <ChevronRight />
              </span>
              <span className="d-none" ref={(element) => chevronDownRef.current[index] = element}>
                <ChevronDown />
              </span>
              <h5>
                {ProductDetails.title}
              </h5>
            </button>
            <div className="d-none" ref={(element) => productDetailsRef.current[index] = element}>
              {productTable}
              <Footnote product={product} />
            </div>
          </div>
        </div>
      )
    }
  );

  return (
    <>
     {productsDom}
    </>
  )
}

export default CompatibilityTable;