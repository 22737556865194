import config from "./config"
import { deleteCookie } from 'cookies-next';


const hideDropDown = () => {
  const mainMenuList = Array.from(document.getElementsByClassName('top-mega-menu-li') as HTMLCollectionOf<HTMLElement>)
  for (let i=0; i<mainMenuList.length; i++) {
    const listClasses = mainMenuList[i].className
    const clicked = listClasses.includes('clicked')
    if (clicked) {
      const clipClasses = listClasses.replace(' clicked', '')
      mainMenuList[i].className = clipClasses
    }
  }
}

 const edbWebLink = (e: any, target: any) => {
    const edbWeb = config.edbWeb.baseUrl;
    const href = String(target.attributes.href.value)
    const firstChar =  href.charAt(0)
    // if first character is "/" then a relative link
    if (firstChar === "/") {
      let edbHost = String(edbWeb)
      const lastChar = edbHost.charAt(edbHost.length-1)
      if (lastChar === "/") {
        edbHost = edbHost.slice(0,-1)
      }
      const menuUrl = edbHost + href
      window.location.assign(menuUrl)
      e.preventDefault()
    }
  }

const getCurrentDate = () => {
  const date_ob = new Date();

  // adjust 0 before single digit date
  const date = ("0" + date_ob.getDate()).slice(-2);

  // current month
  const month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

  // current year
  const year = date_ob.getFullYear();

  return year + "-" + month + "-" + date;

}

const anchorDisplay = ( anchorType: string, path: string ) => {
  let anchorLabel = '';

  if (path === '/resources/platform-compatibility') {
    if (anchorType === 'migrationToolkit') {
      return 'mtk'
    }
    return anchorType;
  }

  switch (anchorType.toLowerCase()) {
    case 'epas':
      anchorLabel = 'edb-postgres-advanced-server';
      break;
    case 'edb-postgres-extended':
      anchorLabel = 'edb-postgres-extended-server';
      break;
    case 'pg':
      anchorLabel = 'postgresql';
      break;
    case 'tpa':
      anchorLabel = 'trusted-postgres-architect';
      break;
    case 'pgd':
      anchorLabel = 'edb-postgres-distributed';
      break;
    case 'barman':
      anchorLabel = 'barman';
      break;
    case 'bart':
      anchorLabel = 'backup-and-recovery-tool';
      break;
    case 'pem':
      anchorLabel = 'postgres-enterprise-manager';
      break;
    case 'efm':
      anchorLabel = 'failover-manager';
      break;
    case 'pgbouncer':
      anchorLabel = 'pgbouncer';
      break;
    case 'pgpool':
      anchorLabel = 'pgpool-ii';
      break;
    case 'eprs':
      anchorLabel = 'replication-server';
      break;
    case 'migrationtoolkit':
      anchorLabel = 'migration-toolkit';
      break;
    case 'livecompare':
      anchorLabel = 'livecompare';
      break;
    case 'postgis':
      anchorLabel = 'postgis';
      break;
    case 'foreigndatawrappers':
      anchorLabel = 'foreign-data-wrappers';
      break;
    case 'connectors':
      anchorLabel = 'edb-connectors';
      break;
    case 'jdbsdrivers':
      anchorLabel = 'third-party-jdbc-drivers';
      break;
    case 'toolkit':
      anchorLabel = 'modified-gpl-and-source-code-utilities';
      break;
    default:
      anchorLabel = anchorType.toLowerCase();
  }

  return anchorLabel;
}

const toggleMenu = () => {
  const edbMainNavigation = document.getElementById("block-edbmainnavigation")
  if (edbMainNavigation) {
    const navBarToggle = edbMainNavigation.getElementsByClassName('navbar-toggle')[0]
    const navBar = edbMainNavigation.getElementsByClassName('navbar-we-mega-menu')[0]

    const navBarClassList = navBar.classList
    console.log('navBarClassList',navBarClassList)

    const toggleClassList = navBarToggle.classList.value
    if (toggleClassList === "navbar-toggle collapsed") {
      navBarToggle.classList.value = "navbar-toggle expanded"
      navBar.classList.add('we-mobile-megamenu-active')
      
    } else {
      navBarToggle.classList.value = "navbar-toggle collapsed"
      if (navBar.classList.contains('we-mobile-megamenu-active')) {
        navBar.classList.remove('we-mobile-megamenu-active')
      }
    }
  }
  
}

const deleteOktaCookies = () => {
  deleteCookie('okta-token-storage_idToken');
  deleteCookie('okta-token-storage_accessToken');
}

export { hideDropDown, edbWebLink, getCurrentDate, anchorDisplay, toggleMenu, deleteOktaCookies }
