import { createSlice } from "@reduxjs/toolkit";

export const productsByOsSlice = createSlice({
  name: 'productsByOs',
  initialState: {
    productsByOs: {},
    loading: false,
  },
  reducers: {
    productsByOsRequested: (productsOs) => {
      productsOs.loading = true;
    },
    productsByOsReceived: (productsOs, action) => {
      productsOs.productsByOs = action.payload;
      productsOs.loading = false;
    },
    productsByOsRequestFailed: (productsOs) => {
      productsOs.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function.
export const { productsByOsRequested, productsByOsReceived, productsByOsRequestFailed } = productsByOsSlice.actions;
export default productsByOsSlice.reducer;