import { createSlice } from "@reduxjs/toolkit";

export const authModalSlice = createSlice({
  name: 'authModal',
  initialState: {
    show: false,
    loading: false,
  },
  reducers: {
    authModalRequested: (modal) => {
      modal.loading = true;
    },
    authModalReceived: (modal, action) => {
      modal.show = action.payload;
      modal.loading = false;
    },
    authModalRequestFailed: (modal) => {
      modal.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function.
export const { authModalRequested, authModalReceived, authModalRequestFailed } = authModalSlice.actions;
export default authModalSlice.reducer;