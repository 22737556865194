import Layout from "../components/layout/Layout";
import HeroFixed from "../components/layout/page/HeroFixed";
import config from "../config";
import Platform from "../components/platform/Platform";

const PlatformCompatibility = () => {
  const heroButtons = [
    {
      label: 'Downloads',
      href: `${config.edbWeb.baseUrl}/software-downloads-postgres`,
      btnClass: 'me-3',
      color: 'white',
    },
    {
      label: 'Subscription Plans',
      href: `${config.edbWeb.baseUrl}/products/plans-comparison`,
      color: 'white',
      type: 'secondary',
    },
    {
      label: 'Release Policy',
      href: `${config.edbWeb.baseUrl}/release-policy`,
      color: 'white',
      type: 'link',
    }
  ];

  return (
    <Layout>
      <HeroFixed
        title="Platform Compatibility"
        description="EDB database distributions and tools are supported on public and private clouds that provide virtualized versions of
        the operating system and CPU architectures listed on this page; this includes AWS, Azure, Google, IBM, and Aliyun."
        class="repos-legacy"
        callToActions={heroButtons}
      />
      <div className="fixed-header-pad bg-light ms-2 pb-4">
        <Platform
          title="Postgres Databases"
          products={['epas', 'epas_extended', 'pg']}
        />
        <Platform
          title="Kubernetes"
          products={['pgk8s', 'cnpg', 'bdrk8s']}
        />
        <Platform
          title="High Availability"
          products={['bdr', /*'harp',*/ 'efm', 'patroni']}
        />
        <Platform
          title="Migration"
          products={['migrationToolkit', 'eprs', 'liveCompare']}
        />
        <Platform
          title="Monitoring/Management"
          products={['pem']}
        />
        <Platform
          title="Backup and Recovery"
          products={['barman']}
        />
        <Platform
          title="Automation"
          products={['tpa']}
        />
      </div>
    </Layout>
  )
}

export default PlatformCompatibility;
