import axios, { AxiosResponse } from "axios";
import config from "../config";
import { 
  OperatingSystems, 
  ProductsByOs, 
  InstructionsReplacements, 
  LegacyInstructions 
} from "../resources/Types";
import { accessToken } from "../resources/OktaSessionData";

const edbApiInstance = axios.create({
  baseURL: config.edbApi.baseUrl,
  timeout: 10000,
});

const responseBody = (response: AxiosResponse) => response.data

const productRegistryRequest = {
  get: (url: string) => edbApiInstance.get<OperatingSystems>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
  }).then(responseBody),
}

export const productRegistryApi = {
  getOperatingSystems : () : Promise<OperatingSystems[]> => productRegistryRequest.get('/api/product-registry/operating-systems'),
  getProductsByOs : ( id: string ) : Promise<ProductsByOs[]> => productRegistryRequest.get(`/api/product-registry/products-by-operating-system/${id}`),
  getInstructions : (packageName: string, distro: string, codeName: string) : Promise<InstructionsReplacements[]> => productRegistryRequest.get(`/api/product-registry/instructions-replacments/${packageName}/${distro}/${codeName}`),
  getInstructionsForLegacy : (versionId: number, osId: string) : Promise<LegacyInstructions[]> => productRegistryRequest.get(`/api/product-registry/commands/${versionId}/os/${osId}`),
}