import axios, { AxiosResponse } from "axios";
import config from "../config";
import { ProductsMeta } from "../resources/Types";

interface Product {
  title: string;
  field_installer_version: string;
  field_sub_version: string;
  field_os: string;
  field_installation_method: string;
  field_supported_os: string;
  uuid: string;
  field_show_this_version: string;
  field_campaign_id: string;
  field_product_category: string;
  field_asset_details: string;
}

const edbApiInstance = axios.create({
  baseURL: config.edbApi.baseUrl,
  timeout: 5000,
});

const responseBody = (response: AxiosResponse) => response.data

const productsRequest = {
  get: (url: string) => edbApiInstance.get<Product>(url).then(responseBody),
}

const productUrlRequest = {
  get: (url: string) => edbApiInstance.get<string>(url, {
    headers: {
      'Authorization': `Basic ${config.productRegistry.basicAuth}`
    }
  }).then(responseBody)
}

export const ProductsApi = {
  getProducts : () : Promise<Product[]> => productsRequest.get('/api/download/product-api'),
  getProductsDW : () : Promise<Product[]> => productsRequest.get('/api/download/product-api-dw'),
  getProductsMetaListDW : () : Promise<ProductsMeta> => productsRequest.get('/api/download/software-download-postgres-page/product-api-dw'),
  getProductUrlDW : ( uuid: string ) : Promise<string> => productUrlRequest.get(`/api/download/product-dw/${uuid}`)
 }