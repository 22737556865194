import axios, { AxiosResponse } from "axios";
import config from "../config";
import { Okta, OktaReposUserName } from "../resources/Types";
import { accessToken } from "../resources/OktaSessionData";

const edbApiInstance = axios.create({
  baseURL: config.edbApi.baseUrl,
  timeout: 10000,
});

const responseBody = (response: AxiosResponse) => response.data

const oktaUserRequest = {
  get: (url: string) => edbApiInstance.get<Okta>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
  }).then(responseBody),
}

export const OktaUserApi = {
  getOktaUser : () : Promise<Okta[]> => oktaUserRequest.get('/api/user'),
  getOktaUserUsername : () : Promise<OktaReposUserName> => oktaUserRequest.get('/api/user/username'),
}