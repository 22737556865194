import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";

const WhichSubscriptionPlanModal = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
    <div>
      <Button 
        variant="link" 
        onClick={handleShow}
        className="text-decoration-none p-0 mb-3"
      >
        Do you have both Enterprise and Standard subscription plans?
      </Button>
    </div>
    <Modal 
      show={show} 
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-0"></Modal.Header>
      <Modal.Body>
        <div className="px-6 pb-6">
          <p className="fw-bold">
            Do you have both Enterprise and Standard subscription plans?
          </p>
          <p>
            For customers with both Standard and Enterprise, please note that these scripts will default to Enterprise.
          </p>
          <p>
          In order to use these scripts for Standard repositories, please use the Auto option under Step 1 and replace "enterprise" with "standard".
          </p>
        </div>
      </Modal.Body>
    </Modal>
  </>
  )
}

export default WhichSubscriptionPlanModal;