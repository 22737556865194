const Repos2KubernetesDocLinks = () => {
  return (
    <div className=" pt-2 px-3-mobile container mobile-container">
      <h2 id="repository-packages">
        EDB Postgres for Kubernetes and EDB Postgres Distributed for Kubernetes
      </h2>
      <p>
        EDB Postgres for Kubernetes and EDB Postgres Distributed for Kubernetes
        are enterprise-grade Kubernetes operators developed by EnterpriseDB to
        manage PostgreSQL and distributed PostgreSQL workloads across any
        supported Kubernetes cluster. These operators are designed to deliver
        robust automation, high availability, and scalability, making them ideal
        for deployment in private, public, or hybrid cloud environments.
      </p>
      <p>
        For further information and details, including installation and
        evaluation instructions, please refer to the EDB Postgres for Kubernetes
        and EDB Postgres Distributed for Kubernetes documentation.
      </p>
      <div className="card shadow py-3 ps-4 mb-3 bg-body rounded">
        <div>
          <ul className="disc-style mb-0">
            <li>
              <a
                className="link-teal"
                href="https://www.enterprisedb.com/docs/postgres_for_kubernetes/latest"
              >
                EDB Postgres for Kubernetes
              </a>
            </li>
            <li>
              <a
                className="link-teal"
                href="https://www.enterprisedb.com/docs/postgres_distributed_for_kubernetes/latest/"
              >
                EDB Postgres Distributed for Kubernetes
              </a>
            </li>
          </ul>
        </div>
      </div>
      <p className="mb-5">
        <span className="fw-600">Note for EDB customers: </span>
        To request a trial license key for EDB Postgres Kubernetes please
        contact your sales representative or you can contact our EDB Technical
        Support Team by email at{" "}
        <a href="mailto:techsupport@enterprisedb.com">
          techsupport@enterprisedb.com{" "}
        </a>{" "}
        or through our support portal{" "}
        <a href="https://techsupport.enterprisedb.com">
          https://techsupport.enterprisedb.com
        </a>
        . Please allow 24 hours for your license to be generated and delivered
        to you and if you need any additional support please do not hesitate to
        contact us.
      </p>
    </div>
  );
};

export default Repos2KubernetesDocLinks;
