import React, { useLayoutEffect } from "react";
import { operatingSystemsApi, productsByOsApi } from "../../client/edbApiClient";
import { useSelector, useDispatch } from "react-redux";
import { operatingSystemsReceived } from "../../store/operatingSystemsSlice";
import { productsByOsReceived, productsByOsRequested } from "../../store/productsByOsSlice";
import { instructionsReplacementRemoveAllPackages } from "../../store/instructionsReplacementSlice";
import { legacyOsReceived } from "../../store/legacyIdsSlice";
import { OperatingSystems, ProductsByOs } from "../../resources/Types";

interface operatingSystemsState {
  operatingSystems: OperatingSystems
}

interface osData {
  id?: number;
  name?: string | null;
  display_name?: string | null;
  architecture?: string | null;
  code_name?: string | null;
  pre_install_commands?: string | null;
  command_line?: string | null;
  effdt_start?: string | null;
  effdt_end?: string | null;
}

const SelectPlatform = () => {
  const allOs = useSelector((state: operatingSystemsState) => state.operatingSystems);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    fetchOperatingSystems()
  }, [])

  const fetchOperatingSystems = async () => {
    return await operatingSystemsApi()
    .then((res: OperatingSystems | unknown) => {
      // Save to the redux store
      dispatch(operatingSystemsReceived(res));
    })
  }

  // Platform select change event.
  const handleChange = ( event: React.ChangeEvent<HTMLSelectElement> ) => {
    // First reset productsByOS redux store value
    // and reset instructionsReplacement redux store value.
    dispatch(productsByOsReceived({}));
    dispatch(instructionsReplacementRemoveAllPackages());

    // Then, get products by OS.
    fetchProductsByOs(event.target.value);
    // Save selected os to the store.
    dispatch(legacyOsReceived(event.target.value));
  }

  const fetchProductsByOs = async (id: string) => {
    // Set productsByOs loading to true.
    // to show the spinner on the instructions component.
    dispatch(productsByOsRequested());

    if (id !== "") {
      return await productsByOsApi(id)
      .then((res: ProductsByOs | unknown) => {
        // Save to the redux store
        dispatch(productsByOsReceived(res));
        // the received will also set productsByOs loading to false.
        // for hiding the spinner on the instructions component.
      })
    }
    else {
      dispatch(productsByOsReceived({}));
    }
  }

  let osData: osData[] = [];
  if (typeof allOs !== "undefined" && 
  'os' in allOs && typeof allOs.os !== "undefined" && 'data' in allOs.os && 
  typeof allOs.os.data !== "undefined" && allOs.os.data.length) {
    osData = allOs.os.data;
  }

  return (
    <div className="card shadow p-3 mb-5 bg-body rounded">
      <div className="card-body">
        <h5>Select Platform:</h5>
        <select className="platform-select form-select" onChange={handleChange}>
          <option value={''}>-- Select a platform --</option>
          {osData.map((platform: osData) => (
            <option key={platform.id} value={platform.id}>
              {platform.display_name}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default SelectPlatform;