import { createSlice } from "@reduxjs/toolkit";

const packages: { package: string }[] = [];

export const instructionsReplacementSlice = createSlice({
  name: 'instructionsReplacement',
  initialState: {
    instructions: {},
    loading: false,
    packages: packages,
  },
  reducers: {
    instructionsReplacementRequested: (instructionsReplacement) => {
      instructionsReplacement.loading = true;
    },
    instructionsReplacementReceived: (instructionsReplacement, action) => {
      instructionsReplacement.instructions = action.payload;
      instructionsReplacement.loading = false;
    },
    instructionsReplacementAddPackage: (instructionsReplacement, action) => {
      instructionsReplacement.packages.push(action.payload);
    },
    instructionsReplacementRemovePackage: (instructionsReplacement, action) => {
      const find = instructionsReplacement.packages.indexOf(action.payload);
      if (find > -1) {
        instructionsReplacement.packages.splice(find, 1);
      }
    },
    instructionsReplacementRemoveAllPackages: (instructionsReplacement) => {
      instructionsReplacement.packages = [];
    },
    instructionsReplacementFailed: (instructionsReplacement) => {
      instructionsReplacement.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function.
export const { 
  instructionsReplacementRequested, 
  instructionsReplacementReceived, 
  instructionsReplacementAddPackage,  
  instructionsReplacementRemovePackage, 
  instructionsReplacementRemoveAllPackages,
  instructionsReplacementFailed 
} = instructionsReplacementSlice.actions;
export default instructionsReplacementSlice.reducer;