import { useState, useEffect } from "react";
import Layout from "../components/layout/Layout";
import "../crossover1.css"
import { dwProductUrlApi } from "../client/edbApiClient";
import PgvHeroBanner from "../components/layout/page/PgvHeroBanner";

// This page called randomly as a/b test by /downloads/postgres-postgresql-downloads.html
const CrossOver1 = () => {

  const [resourceUri, setResourceUri] = useState('')    

  const params = new URLSearchParams(window.location.search);
  const uuid = params.get('uuid')

  useEffect(() => {
    if (uuid != null) {
        findDownloadAssetInfo(uuid)
    }
  }, [1])


  const findDownloadAssetInfo = async (uuid: string) => {
   //  const token = await getDrupalToken()
   //  .then(res => {
   //    return res;
   //  })

    //const response = await communityProductApi(uuid, token);
    const response = await dwProductUrlApi(uuid);

    if (typeof response === "string" && response !== "" && response.substring(0, 4) === "http") {
      // Set resource url.
      setResourceUri(response);

      // Automatically download the installer file.
      document.location.href = response;
    }
    
   //  if ('data' in response && 'attributes' in response.data) {
   //      //set resource url
   //      setResourceUri(response.data.attributes.field_resource_uri)
        
   //      //automatically download the installer file
   //      document.location.href = response.data.attributes.field_resource_uri
   //  }
  };

  return (
    <Layout>

      <div className="layout-1st-child">
         <section className="download-message-blade">
            <div className="download-message-banner">
               <div className="download-message-container">
                  <h4 id="download-message-title">Your download should begin in a few seconds</h4>
                  <div className="click-message">
                        <a id="download-link" href={`${resourceUri}`}>Click here</a> if your download does not start automatically.
                  </div>
               </div>
               <div id="installation-instructions-container">
                  <a className="installation-instruction-link" href="/postgres-tutorials/installation-postgresql-mac-os">
                     <svg viewBox="0 0 50 50" width="1em" height="1em" fill="currentColor" className="css-yn4r38">
                        <path d="M44.527 34.75c-1.078 2.395-1.597 3.465-2.984 5.578-1.941 2.953-4.68 6.64-8.063 6.664-3.011.028-3.789-1.965-7.878-1.93-4.086.02-4.938 1.97-7.954 1.938-3.386-.031-5.976-3.352-7.918-6.3-5.43-8.27-6.003-17.966-2.648-23.122 2.375-3.656 6.129-5.805 9.656-5.805 3.594 0 5.852 1.973 8.82 1.973 2.883 0 4.637-1.976 8.794-1.976 3.14 0 6.46 1.71 8.836 4.664-7.766 4.257-6.504 15.347 1.34 18.316zM31.195 8.469c1.512-1.942 2.66-4.68 2.242-7.469-2.464.168-5.347 1.742-7.03 3.781-1.528 1.86-2.794 4.617-2.302 7.285 2.692.086 5.477-1.52 7.09-3.597z"></path>
                     </svg>
                       <span className="ml-half">Installation instructions</span>
                  </a>
               </div>
            </div>


         </section>
      
         <section className="hero-blade">
           <div className="hero-blade-background"><img alt="" src="../../images/bigAnimal/clouds-sky.svg" className="css-1wzn25j" /></div>
           <div className="hero-banner">
              <div className="hero-banner-image width-40" >
                  <img src="../../images/terminal-pic.png" alt="big animal logo" className="hero-image" />
              </div>
              <div className="hero-banner-text width-50">
                  <div className="billboard-info">
                     <h1 className="comp-heading">EDB Postgres<sup>®</sup> AI</h1>
                     <h3></h3>
                  </div>
                  <p className="hero-banner-text-headline">
                     Get Postgres from a Builder, Not a Borrower
                  </p>
                  <p className="hero-banner-text-sub-headline">
                     EDB Postgres AI Fully Managed Database-as-a-Service
                  </p>
                  <ul className="hero-banner-text-list">
                     <li className="hero-banner-text-list-item">
                        Distributed Postgres
                     </li>
                     <li className="hero-banner-text-list-item">
                        Unmatched Oracle Compatibility
                     </li>
                     <li className="hero-banner-text-list-item">
                        No Vendor Lock-In
                     </li>
                  </ul>
                  <div className="hero-banner-text-button-wrapper mt-50">
                     <div className="cta-teal">
                        <a href="https://www.enterprisedb.com/accounts/register/biganimal?utm_medium=web&utm_source=crossover&utm_content=bareg">Start Free</a>
                        
                     </div>
                     <div className="cta-plain ml-50">
                        <a href="https://www.enterprisedb.com/products/biganimal-cloud-postgresql?utm_medium=web&utm_source=crossover&utm_content=baprod">Learn More</a>
                     </div>

                  </div>
              </div>              
           </div>
         </section>
         <section className="postgres-vision-blade">
            <div className="announcement position-relative">
               <div className="pgv-blade-background"><img alt="" src="../../images/bigAnimal/PostgresVisionBanner.svg" className="cover-fit-100"/></div>
               <div className="hero-pattern software-downloads-postgres position-absolute no-show">
               </div>
               <div className="hero-content container">
                  <div className="hero-content-wrapper">
                     <div className="hero-content-inner-wrapper d-flex align-items-center justify-content-center">
                        <img alt="" src="../../images/bigAnimal/PostgresVisionLogo.svg" className="fix-200 mr-50"/>
                        <div className="hero-location fwt-500">
                           Our flagship user conference is coming to a city near you.
                        </div>
                        <nav className="hero-button">
                        <a href="https://www.enterprisedb.com/resources/events/edb-postgres-vision-2023" className="btn-white-outline ml-50">Learn More</a> 
                        </nav>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="main-content-blade">
            <div className="main-content-body">
               <div className="main-content-body-title-wrapper mt-50">
                  <h3 className="main-content-body-title">Get more out of PostgreSQL with EDB</h3>
                  <div className="main-content-body-sub-title">EDB builds software for teams who need to do more and go faster with PostgreSQL.</div>
               </div>
               <div className="teal-banner">
                  <div className="biganimal-logo-wrapper width-15">
                     <img src="../images/edb_postgres_color_white.svg" alt="EDB logo" />
                  </div>
                  <div className="big-animal-plan-link-wrapper">
                     <a className="sub-banner-text" title="" href="/products/biganimal-cloud-postgresql">
                        <div>
                           <div className="ba-link-text text-bold">EDB Postgres AI Plan </div>
                              <p className="ba-link-text text-plain">Fully managed PostgreSQL in the cloud</p>
                           </div>
                     </a>
                  </div>
               </div>
               <div className="categories-title mt-4 mb-2">
                  Categories           
               </div>
               <div className="categories-wrapper d-flex justify-content-between">
                  <div className="categories-gray-block">
                     <ul className="categories-gray-block-ul">
                        <li className="flex-flex-start mt-3">
                           <a className="categories-list-link" title="" href="/products/edb-postgres-advanced-server">
                              <div>
                                 <div className="">EDB Postgres Advanced Server </div>
                                 <p className="fwt-300">Enterprise-ready, Oracle-compatible Postgres</p>
                              </div>
                           </a>
                        </li>
                        <li className="flex-flex-start mt-3">
                           <a className="categories-list-link" title="" href="/products/edb-postgres-distributed">
                              <div>
                                 <div className="">EDB Postgres Distributed </div>
                                 <p className="fwt-300">Extreme high availability for Postgres</p>
                              </div>
                           </a>
                        </li>
                        <li className="flex-flex-start mt-3">
                           <a className="categories-list-link" title="" href="/products/edb-postgres-for-kubernetes">
                              <div>
                                 <div className="">EDB Postgres for Kubernetes </div>
                                 <p className="fwt-300">Kubernetes operator and container images</p>
                              </div>
                           </a>
                        </li>
                        <li className="flex-flex-start my-3">
                           <a className="categories-list-link" title="" href="/products/postgres-enterprise-manager">
                              <div>
                                 <div className="">Postgres Enterprise Manager </div>
                                 <p className="fwt-300">Manage, monitor, and optimize PostgreSQL</p>
                              </div>
                           </a>
                        </li>
                     </ul>
                  </div>
                  <div className="categories-gray-block">
                     <ul className="categories-gray-block-ul">
                        <li className="flex-flex-start mt-3">
                           <a className="categories-list-link" title="" href="/products/postgresql-databases">
                              <div>
                                 <div className="">Postgres Databases </div>
                                 <p className="fwt-300">Enterprise-ready PostgreSQL</p>
                              </div>
                           </a>
                        </li>
                        <li className="flex-flex-start mt-3">
                           <a className="categories-list-link" title="" href="/products/migration">
                              <div>
                                 <div className="">EDB Postgres Migration </div>
                                 <p className="fwt-300">Schema and data migration to PostgreSQL</p>
                              </div>
                           </a>
                        </li>
                        <li className="flex-flex-start mt-3">
                           <a className="categories-list-link" title="" href="/products/monitoring">
                              <div>
                                 <div className="">PostgreSQL Monitoring &amp; Query Performance </div>
                                 <p className="fwt-300">Manage, monitor, optimize PostgreSQL</p>
                              </div>
                           </a>
                        </li>
                        <li className="flex-flex-start my-3">
                           <a className="categories-list-link" title="" href="/products/integrations">
                              <div>
                                 <div className="">Foreign Data Wrappers </div>
                                 <p className="fwt-300">Connecting to PostgreSQL</p>
                              </div>
                           </a>
                        </li>
                     </ul>
                  </div>
                  <div className="categories-gray-block">
                     <ul className="categories-gray-block-ul">
                        <li className="flex-flex-start mt-3">
                           <a className="categories-list-link" title="" href="/products/cluster-management">
                              <div>
                                 <div className="">Postgres Clustering with EDB </div>
                                 <p className="fwt-300">High availability for PostgreSQL</p>
                              </div>
                           </a>
                        </li>

                        <li className="flex-flex-start mt-3">
                           <a className="categories-list-link" title="" href="/products/replication">
                              <div>
                                 <div className="">Replication </div>
                                 <p className="fwt-300">Advanced logical replication for PostgreSQL</p>
                              </div>
                           </a>
                        </li>

                        <li className="flex-flex-start mt-3">
                           <a className="categories-list-link" title="" href="/products/backup-recovery">
                              <div>
                                 <div className="">PostgreSQL Database Backup Solutions </div>
                                 <p className="fwt-300">Automate backup and recovery for PostgreSQL</p>
                              </div>
                           </a>
                        </li>
                        
                        <li className="flex-flex-start my-3">
                           <a className="categories-list-link" title="" href="/training">
                              <div>
                                 <div className="">Training and Certification </div>
                                 <p className="fwt-300">Build, enhance and validate PostgreSQL Skills</p>
                              </div>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
               <div className="psql-support">
                  <div className="width-50-big">
                    <h3 className="psql-support-title">PostgreSQL expertise and support</h3>
                    <p className="mb-5">EDB's dedicated team of experts is ready to help you get the most out of PostgreSQL.</p>
                   <div className="mb-0-640"><a className="btn-teal-outline" href="/services-support">Learn about PostgreSQL support plans</a></div>
                  </div>
                  <div className="experts-button-wrapper">
                     <a className="experts-button d-flex" href="/why-edb/postgresql-expertise-community-major-contributors-experts-professional">
                        <div className="expert-button-sub-section">
                           <div className="expert-button-number">
                              41
                           </div>
                           <div className="expert-button-text">
                              EDB contributes to PostgreSQL 15
                           </div>
                        </div>
                        <div className="expert-button-sub-section">
                           <div className="expert-button-number">
                              300
                           </div>
                           <div className="expert-button-text">
                              EDB PostgreSQL expert solving real world problems
                           </div>
                        </div>
                     </a>
                  </div>
               </div>

              <div className="psql-covered">
                  <div className="psql-covered-title">
                     For everything PostgreSQL, EDB has you covered
                  </div>
                  <div className="css-t0hzbj">
                    <a className="css-7hfkez" href="/why-edb/postgresql-expertise-community-major-contributors-experts-professional">
                        <div className="css-byrywa">
                           <img alt="" src="../../images/bigAnimal/psql-elephant.svg" className="fix-100 marginx-auto"/>
                           <p className="css-b3sgxl">We’re Postgres Experts</p>
                           <p className="css-18h88zt">Transformational technology &amp; expertise</p>
                        </div>
                    </a>
                    <a className="css-7hfkez" href="/why-edb/leave-oracle-for-postgresql-migration-postgres-move">
                        <div className="css-byrywa">
                           <img alt="" src="../../images/bigAnimal/balloon-elephant.svg" className="fix-100 marginx-auto"/>
                           <p className="css-b3sgxl">Leave Oracle for PostgreSQL</p>
                           <p className="css-18h88zt">Software &amp; services for the journey</p>
                        </div>
                    </a>
                    <a className="css-7hfkez" href="/why-edb/kubernetes-and-postgresql-docker-container-cloud-native">
                       <div className="css-byrywa">
                          <img alt="" src="../../images/bigAnimal/kubernetes.svg" className="fix-100 marginx-auto"/>
                          <p className="css-b3sgxl">Kubernetes and Postgres</p>
                          <p className="css-18h88zt">Containerized Postgres that runs anywhere</p>
                       </div>
                    </a>
                    <a className="css-7hfkez" href="/why-edb/high-availability-postgresql-deploy-manage-ha-cluster">
                       <div className="css-byrywa">
                          <img alt="" src="../../images/bigAnimal/high-availability.svg" className="fix-100 marginx-auto"/>
                          <p className="css-b3sgxl">High availability for PostgreSQL</p>
                          <p className="css-18h88zt">Clustered PostgreSQL for five 9s</p>
                       </div>
                    </a>
                 </div>
              </div>
           </div>
        </section>
      </div>
    </Layout>
  )
}

export default CrossOver1;
