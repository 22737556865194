import { useRef } from "react";
import ProductsPlaceholder from "../../resources/ProductsPlaceholder";
import ModifiedGPLProductDetails from "./ModifiedGPLProductDetails";
import { dwProductApi } from "../../client/edbApiClient";
import { useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { productsReceived } from "../../store/productSlice";
import AuthModal from "../AuthModal";
import { VerifyAuthenticator } from "@okta/okta-auth-js/types/lib/idx/remediators/Base/VerifyAuthenticator";

interface ModifiedGPLProduct {
  list: {
    title: string;
    field_installer_version: string;
    field_sub_version: string;
    field_os: string;
    field_installation_method: string;
    field_supported_os: string;
    uuid: string;
    field_show_this_version: string;
    field_campaign_id: string;
    field_product_category: string;
    field_asset_details: string;
  }
}

interface productState {
  products: ModifiedGPLProduct;
}

interface authModalState {
  authModal: {
    show: boolean
  };
}

const ModifiedGPLProducts = () => {
  const allProducts = useSelector((state: productState) => state.products.list);
  const showAuthModal = useSelector((state: authModalState) => state.authModal.show)
  const dispatch = useDispatch();

  const productRef = useRef(new Array(Object.keys(ProductsPlaceholder).length));

  useLayoutEffect(() => {
    // Get Products.
    fetchProducts()

    // Scroll Down if anchor tag in url.
    // Need to wait a couple of seconds to allow products from api and build page
    // so that anchor is available to go to.
    setTimeout(() => {
      if (window.location.hash) {
        productRef.current.map((v: HTMLLIElement) => {
          if (window.location.hash === `#${v.id}`) {
            v.scrollIntoView();
            v.focus();
          }
        });
      }
    }, 2000)
  }, [dispatch])

  const fetchProducts = async () => {
    return await dwProductApi()
    .then(res => {
      if (typeof res !== "undefined") {
      // Save to the redux store
      dispatch(productsReceived(res))
      }
      else {
        dispatch(productsReceived([]))
      }
    })
  }

  // Make copy of allProducts to prevent cannot assign read only propert error.
  const allProductsCopy = JSON.parse(JSON.stringify(allProducts));

  return (
    <>
      <ul className="list-unstyled" id="product-list">
        {Object.entries(ProductsPlaceholder).map((product, index) => {
          // only show Modified GPL and Source Code Utilities section
          if (product[0] !== 'toolkit') {
            return false
          }
          let versions = [];
          if (Array.isArray(allProductsCopy) && allProductsCopy.length) {
            versions = allProductsCopy.filter(category => category.field_product_category === product[1].title && category.field_show_this_version === "1")
          }

          return (
            <ModifiedGPLProductDetails 
              key={product[1].title}
              product={product[1]}
              versions={versions}
              ref={(element) => typeof index === "number" ? productRef.current[index] = element : ''}
            />
          )
        })}
      </ul>
      <AuthModal show={showAuthModal} />
    </>
  )
}

export default ModifiedGPLProducts;