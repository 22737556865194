const PagesPlaceholder = Object.freeze({
  // Postgres Software: Other individual software pages (not in menu)
  pem: {
    section: "Postgres Software",
    label: "Postgres Enterprise Manager",
    description: "Manage, monitor, and optimize PostgreSQL",
    longDescription:
      "Postgres Enterprise Manager is the best way to manage, monitor, and optimize PostgreSQL and EDB Postgres Advanced Server.",
    url: "/products/postgres-enterprise-manager-best-gui-tools-database-management",
    icon: "Monitoring",
    meta: {
      title:
        "EDB Postgres Enterprise Manager (PEM) | Database Management System (DBMS) - Postgres Client with GUI",
      description:
        "Configure, optimize, monitor and manage Postgres deployments in one GUI interface with EDB Postgres Enterprise Manager (PEM). Built-in dashboards, customizable alert thresholds, and in-depth diagnostics for database reports make it easy to keep Postgres running smoothly.",
    },
  },
  epas: {
    section: "Services",
    label: "EDB Postgres Advanced Server",
    description: "Enterprise-ready, Oracle-compatible PostgreSQL",
    longDescription:
      "Advanced Server is Oracle database compatible, so leaving Oracle doesn’t mean starting over. It also extends PostgreSQL with security and performance capabilities for enterprises.",
    url: "/products/edb-postgres-advanced-server",
    icon: "DatabaseAdmin",
    meta: {
      title:
        "EDB Postgres Advanced Server - Oracle Compatible | Enterprise Database with Improved Security & Performance",
      description:
        "Advanced Server is Oracle database compatible, so leaving Oracle doesn’t mean starting over. It also extends PostgreSQL with security and performance capabilities for enterprises.",
    },
  },
  migrationPortal: {
    section: "Postgres Software",
    label: "Migration Portal",
    description: "Browser-based schema migration tool",
    longDescription:
      "Migration Portal gives you detail on what will be needed to migrate your Oracle database to PostgreSQL, and then starts the migration by producing DDLs that are compatible with EDB Postgres Advanced Server.",
    url: "/products/migration-free-tool-migrating-oracle-postgresql",
    icon: "Migration",
    meta: {
      title:
        "Migration Portal - Free Tools for Migrating From Oracle to PostgreSQL Database",
      description:
        "Migrate from Oracle to PostgreSQL and perform Oracle database schema assessment without installing anything. Analyze and get compatibility reports free. Convert data types, tables, constraints and many more with free migration tool.",
    },
  },
  migrationToolkit: {
    section: "Postgres Software",
    label: "Migration Toolkit",
    description: "Command-line data migration tool",
    longDescription:
      "A feature-rich command-line tool to migrate tables and data from your DBMS to PostgreSQL or EDB Postgres Advanced Server.",
    url: "/products/migration-toolkit-move-oracle-postgresql",
    icon: "Toolbox",
    meta: {
      title:
        "Migration Toolkit: A Feature Rich Command-Line Tool | Migrate Data to PostgreSQL from Oracle, MSSQL and MySQL",
      description:
        "Migrate your data and structures to Postgres with feature-rich, highly scriptable command-line Migration Toolkit. Migrate from Oracle, Microsoft SQL Server, MySQL or Sybase to Postgres immediately with online migration or generate scripts to use a later time.",
    },
  },
  harp: {
    section: "Postgres Software",
    label: "High Availability Routing for Postgres",
    longDescription:
      "An EDB tool for managing high availability for PostgreSQL, EDB Postgres Extended, and EDB Postgres Advanced Server clusters using Bi-Directional Replication (BDR) on Linux",
    icon: "Replication",
    meta: {
      title: "High Availability Routing for Postgres",
      description:
        "An EDB tool for managing high availability for PostgreSQL, EDB Postgres Extended, and EDB Postgres Advanced Server clusters using Bi-Directional Replication (BDR) on Linux.",
    },
  },
})

export default PagesPlaceholder;