import axios, { AxiosResponse } from "axios";
import config from "../config";
import { ReposAccess } from "../resources/Types";
import { accessToken } from "../resources/OktaSessionData";

const edbApiInstance = axios.create({
  baseURL: config.edbApi.baseUrl,
  timeout: 100000,
});

const responseBody = (response: AxiosResponse) => response.data

const repoAccessRequest = {
  get: (url: string) => edbApiInstance.get<ReposAccess>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
  }).then(responseBody),
}

export const ReposAccessApi = {
  getRepoAccess : () : Promise<ReposAccess[]> => repoAccessRequest.get('/api/repos/access'),
}