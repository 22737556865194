
import HeaderDropdowns from "./HeaderDropdowns"

const HeaderMenu = () => {
  return (
    <div>
      <HeaderDropdowns />
    </div>
  )
}

export default HeaderMenu