import { createSlice } from "@reduxjs/toolkit";

export const oktaProfileSlice = createSlice({
  name: 'profile',
  initialState: {
    profile: {},
    loading: false,
  },
  reducers: {
    profileRequested: (profile) => {
      profile.loading = true;
    },
    profileReceived: (profile, action) => {
      profile.profile = action.payload;
      profile.loading = false;
    },
    profileRequestFailed: (profile) => {
      profile.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function.
export const { profileRequested, profileReceived, profileRequestFailed } = oktaProfileSlice.actions;
export default oktaProfileSlice.reducer;