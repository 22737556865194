import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CopyIcon, CopiedIcon } from "./icons/copy";
import { LinkIcon } from './icons/link';
import { RevealPassword, HidePassword } from "./icons/reveal_hide";


interface ToolTipProps {
  keyId: string;
  placement: 'top' | 'right' | 'bottom' | 'left';
  tooltipLabel: string;
  tooltipClass: string;
  component: 'CopyIcon' | 'CopiedIcon' | 'RevealPassword' | 'HidePassword' | 'LinkIcon';
}
const ToolTipOverlay = (props: ToolTipProps) => {
  let component = <CopyIcon />
  const tooltipClass = props.tooltipClass !== "" ? ` ${props.tooltipClass}` : "";

  switch(props.component) {
    case 'CopyIcon':
      component = <CopyIcon />
      break;
    case 'CopiedIcon':
      component = <CopiedIcon />
      break;
    case 'RevealPassword':
      component = <RevealPassword />
      break;
    case 'HidePassword':
      component = <HidePassword />
      break;
    case 'LinkIcon':
      component = <LinkIcon />
      break;
    default:
      component = <CopyIcon />
  }

  return (
    <OverlayTrigger
      key={props.keyId}
      placement={props.placement}
      overlay={
        <Tooltip id={`tooltip-${props.keyId}`}>
          {props.tooltipLabel}
        </Tooltip>
      }
    >
      <Button variant="link" className={`tooltip-btn${tooltipClass}`}>
        {component}
      </Button>
    </OverlayTrigger>
  )
}

export default ToolTipOverlay;