import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
  name: 'products',
  initialState: {
    list: [],
    loading: false,
  },
  reducers: {
    productsRequested: (products) => {
      products.loading = true;
    },
    productsReceived: (products, action) => {
      products.list = action.payload;
      products.loading = false;
    },
    productsRequestFailed: (products) => {
      products.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function.
export const { productsRequested, productsReceived, productsRequestFailed } = productSlice.actions;
export default productSlice.reducer;