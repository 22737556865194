import React from 'react'
//import Image from 'next/image'
//import LogoSvg from '../../components/images/edb_landscape_color_white.svg';
import styles from './styles.module.scss';

const Logo = () => {
  return (
    <div className={styles.headerLogoWrapper}>
      <a className={styles.siteLogo} href="/" rel="home">
        <img
          src="../images/edb_landscape_color_white.svg" alt="EDB logo" 
        />
      </a>
    </div>
  )
}

export default Logo