export const CopyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 24 24"><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>
  )
}

export const CopiedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 24 24"><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" fill="#000"></path><rect fill="#000" x="9" y="9" width="14" height="16" rx="2" ry="2" transform="translate(-3, -3)"></rect><path d="M 14.5 2.7929688 L 14.146484 3.1464844 L 5.5 11.792969 L 1.8535156 8.1464844 L 1.5 7.7929688 L 0.79296875 8.5 L 1.1464844 8.8535156 L 5.5 13.207031 L 14.853516 3.8535156 L 15.207031 3.5 L 14.5 2.7929688 z" fill="#fff" stroke="#fff" strokeWidth="1px" transform="scale(0.75, 0.75) translate(10 10)"></path></svg>
  )
}