import AuthMessage from "../../AuthMessage";
import ButtonEdb from "../../ButtonEdb";

interface HeroProps {
  title: string;
  class: string;
  description?: string;
  sublink?: {
    title: string;
    url: string;
  };
  callToActions?: {
      label: string;
      href: string;
      btnClass?: string;
      color?: string;
      type?: string;
    }[],
  showAuth?: boolean;
}

const Hero = ( props: HeroProps ) => {
  let title = '';
  let url = '';
  if (props.sublink) {
    title = props.sublink.title;
    url = props.sublink.url;
  }

  return (
    <div className="hero hero-text hero-text-white position-relative">
      <div className={`hero-pattern ${props.class} position-absolute`}>
        <img alt="" src="/images/patterns/swirls-aqua.svg" className="swirl" />
      </div>
      <div className="hero-content container">
        <div className="hero-content-wrapper">
         <div className="hero-content-inner-wrapper">
            <h1 className="hero-header d-flex justify-content-between">
               <span className="header-text">
                  {props.title}
               </span>
               {props.sublink && (
                <span className="header-link">
                  <a 
                    href={`/${url}`}
                    className="text-decoration-none text-white fs-6"
                  >
                    {title}
                  </a>
                </span>
               )}
            </h1>
            {props.description && (
              <p className="description">
                {props.description}
              </p>
            )}
            {props.callToActions && (
              <nav className="d-flex">
                {props.callToActions.map((action, index) => (
                  <ButtonEdb
                    key={index}
                    label={action.label}
                    href={action.href}
                    btnClass={'btnClass' in action ? action.btnClass : ""}
                    color={'color' in action ? action.color : "orange"}
                    type={'type' in action ? action.type : "primary"}
                  />
                ))}
              </nav>
            )}
            <p className="p-spacer"></p>
         </div>
          {props.showAuth && (
            <div className="mt-4-mobile">
              <AuthMessage />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Hero;