import styles from './styles.module.scss';
import { LinkedIn, Twitter, YouTube, Facebook } from '../../icons/SocialMedia';
//import Image from 'next/image'
//import LogoSvg from '../../components/images/EDB-Logo_footer@4x_0.png';
const Footer = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <div className={styles.footer} id="footer">
      <div className={styles.footerContainer}>
        <div className={styles.footerRegion}>
          <div className={styles.footerLogoWrapper}>
            <img src="../images/EDB-Logo_footer@4x_0.png" alt="EDB logo" className="width300" />
          </div>
          
          <div className={styles.footerNavMain}>
            <ul className='flex justify-between'>
              <li>
                <span className={styles.footerMenuHeader}>
                  Products and Solutions
                </span>
                <ul>
                  <li className={styles.footerMenuItem}>
                    <a href="/solutions">
                      Solutions
                    </a>
                  </li>
                  <li className={styles.footerMenuItem}>
                    <a href="/products">
                      Software Portfolio
                    </a>
                  </li>
                  <li className={styles.footerMenuItem}>
                    <a href="/products/plans-comparison">
                      Subscription Plans Comparison
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <span className={styles.footerMenuHeader}>
                  Topics
                </span>
                <ul>
                  <li className={styles.footerMenuItem}>
                    <a href="/blog/what-cloud-computing-and-what-does-it-mean-postgres-enterprise">
                      What is Cloud Computing?
                    </a>
                  </li>
                  <li className={styles.footerMenuItem}>
                    <a href="/blog/what-database-service-dbaas-tutorial-how-use-managed-database-service-postgresql">
                      What is Database-as-a-Service?
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <span className={styles.footerMenuHeader}>
                  Press Room
                </span>
                <ul>
                  <li className={styles.footerMenuItem}>
                    <a href="/news">
                      Press Releases
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <span className={styles.footerMenuHeader}>
                  Company
                </span>
                <ul>
                  <li className={styles.footerMenuItem}>
                    <a href="/company/leadership">
                      Leadership Team
                    </a>
                  </li>
                  <li className={styles.footerMenuItem}>
                    <a href="/careers">
                      Careers
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul className={styles.socialMediaUl}>
                  <li className='px-3'>
                    <a href="https://www.linkedin.com/company/enterprisedb">
                      <LinkedIn />
                    </a>
                  </li>
                  <li className='px-3'>
                    <a href="https://twitter.com/EDBPostgres">
                      <Twitter />
                    </a>
                  </li>
                  <li className='px-3'>
                    <a href="https://www.youtube.com/user/EnterpriseDB">
                      <YouTube />
                    </a>
                  </li>
                  <li className='px-3'>
                    <a href="https://www.facebook.com/EDBpostgres">
                      <Facebook />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className={styles.footerBottom}>
            <ul className='flex'>
              <li className={styles.footerBottomMenuItem}>
                <span>
                  © {currentYear} EDB
                </span>
              </li>
              <li className={styles.footerBottomMenuItem}>
                <a href="/legal">
                  Legal Notices
                </a>
              </li>
              <li className={styles.footerBottomMenuItem}>
                <a href="https://info.enterprisedb.com/rs/069-ALB-339/images/ESG%20statement%202022.pdf">
                  ESG
                </a>
              </li>
              <li className={styles.footerBottomMenuItem}>
                <a href="/privacy-policy">
                  Privacy Policy
                </a>
              </li>
              <li className={styles.footerBottomMenuItem}>
                <a href="https://info.enterprisedb.com/manage-email-preferences-unknown">
                  Marketing Preferences
                </a>
              </li>
              <li className={styles.footerBottomMenuItem}>
                <a href="/edb-cookie-preferences">
                  Cookie Preferences
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer