import { createSlice } from "@reduxjs/toolkit";

export const downloadInstallerSlice = createSlice({
  name: 'downloadInstaller',
  initialState: {
    installer: '',
    loading: false,
  },
  reducers: {
    downloadInstallerRequested: (download) => {
      download.loading = true;
    },
    downloadInstallerReceived: (download, action) => {
      download.installer = action.payload;
      download.loading = false;
    },
    downloadInstallerRequestFailed: (download) => {
      download.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function.
export const { downloadInstallerRequested, downloadInstallerReceived, downloadInstallerRequestFailed } = downloadInstallerSlice.actions;
export default downloadInstallerSlice.reducer;