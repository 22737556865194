import axios, { AxiosResponse } from "axios";
import config from "../config";

interface HeaderFooter {
  [index: number]: { 
    id: number; 
    header: string; 
    footer: string;
    header_css: string;
    footer_css: string;
  };
}
  
const edbApiInstance = axios.create({
  baseURL: config.edbApi.baseUrl,
  timeout: 10000,
});

const responseBody = (response: AxiosResponse) => response.data
// bearer token stubbed in, will need manual update for now
const headerFooterRequest = {
  get: (url: string) => edbApiInstance.get<HeaderFooter>(url, {
      headers: {
        Authorization: "Bearer eyJraWQiOiI2RlpwWkM5LU50THVtTmJiRWt6RFNZQXpkRFpaZ3c4WGRmOG9hT29xcVhvIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULmdTNTJMS0dja3NPRzMtRFI0TFJhZmpvQnNJeU81bDU2REhyUnZPRHVBdVkiLCJpc3MiOiJodHRwczovL2VudGVycHJpc2VkYi5va3RhcHJldmlldy5jb20vb2F1dGgyL2RlZmF1bHQiLCJhdWQiOiJhcGk6Ly9kZWZhdWx0IiwiaWF0IjoxNjY2MzczNDk0LCJleHAiOjE2NjYzNzUyOTQsImNpZCI6IjBvYXpramszNWVoWTYzTTB3MGg3Iiwic2NwIjpbImFwaV9hY2Nlc3MiXSwic3ViIjoiMG9hemtqazM1ZWhZNjNNMHcwaDcifQ.MIlgbrBctqKtYA8FXDSQK7A5lVbyIsPcoMdZDpil_FHwo6Dgy7rbvsgqyajNkfjvaLLcw89sopSPuQ7Dco9vW8JqwYpi1GBCTf5K1gLPhgUcWP-z4UVSQ3AsOXQtavSOLckF-XEeBnHaPvTVj54vCFSbNGfhHPGqp4bcYD9g4WKSYHe9DfR8Nek0A1PVp3HBvZAvOe_y_x16GkCFV5MOhP4CC-yAbane6kx3EEKJ-_huB84qYYKIpZxsSJhI5O8hsqYulEI2xSXMVMcWwNUsqqvwH6ySMlcpxsV16I5CDoj2C78_NrtFqKj2VWLhy1bwSAR5jORiuNS4mu0RYjEqCA"
      },
  }).then(responseBody),
}

export const HeaderFooterApi = {
  getHeaderFooter : () : Promise<HeaderFooter[]> => headerFooterRequest.get('/admin-api/data-warehouse/header-footer/1'),
}