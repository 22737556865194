import React from "react";

interface PageSectionProps {
  children: React.ReactNode
}

const PageSection = ({ children }: PageSectionProps) => {
  return (
    <div className="page-section py-5">
      <div className="page-section-inner mar-x-5">
        {children}
      </div>
    </div>
  )
}

export default PageSection;