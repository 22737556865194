const CPU_LINUX_X86_64 = "Linux x86-64 (amd64)";
const CPU_LINUX_POWER89_LE = "Linux on IBM Power (ppc64le)";
const CPU_LINUX_ONE = "IBM LinuxOne (s390x)";
const CPU_WINDOWS_X86_64 = "Windows x86-64";
const OS_RHEL = "Red Hat Enterprise Linux";
const OS_OL = "Oracle Linux";
const OS_CENT = "CentOS";
const OS_ROCKY = "Rocky Linux/AlmaLinux";
const OS_SLES = "SUSE Linux Enterprise Server";
const OS_UBUNTU = "Ubuntu";
const OS_DEBIAN = "Debian";
const OS_WINDOWS_SERVER = "Windows Server";
const OS_WINDOWS = "Windows";
const OS_MAC = "MacOS";
const OS_MAC_X86 = "MacOS (x86)";
const OS_MAC_ARM = "MacOS (arm64)";
const KUBE_K8S = "Kubernetes*";
const KUBE_K8S_nostar = "Kubernetes";
const KUBE_RHOS = "Red Hat OpenShift**";
const KUBE_RHOS_nostar = "Red Hat OpenShift";

export default {
  epas: {
    versions: [
      {
        name: "16.x",
        generalAvailability: "Nov 9, 2023",
        supportEndDate: "Nov 9, 2028",
      },
      {
        name: "15.x",
        generalAvailability: "Feb 14, 2023",
        supportEndDate: "Feb 13, 2028",
      },
      {
        name: "14.x",
        generalAvailability: "Dec 2, 2021",
        supportEndDate: "Dec 1, 2026",
      },
      {
        name: "13.x",
        generalAvailability: "Nov 17, 2020",
        supportEndDate: "Nov 16, 2025",
      },
      {
        name: "12.x",
        generalAvailability: "Dec 10, 2019",
        supportEndDate: "Dec 9, 2024",
      }
      /*{
        name: "11.x",
        generalAvailability: "Nov 21, 2018",
        supportEndDate: "Nov 20, 2023",
      },*/
    ],
    platforms: [
      
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7","9/8/7", "9/8/7", "9/8/7", "9/8/7"],
          },
          {
            name: OS_OL,
            support: ["9/8/7","9/8/7", "9/8/7", "9/8/7", "9/8/7"],
          },
          {
            name: OS_ROCKY,
            support: ["9/8","9/8", "9/8", "9/8", "9/8"],
          },
          {
            name: OS_CENT,
            support: ["7","7", "7", "7", "7"],
          },
          {
            name: OS_SLES,
            support: [
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
            ],
          },
          {
            name: OS_UBUNTU,
            support: [
              "22.04/20.04",
              "22.04/20.04", 
              "22.04/20.04", 
              "22.04/20.04", 
              "22.04/20.04"
            ],
          },
          {
            name: OS_DEBIAN,
            support: [
              "11/10",
              "11/10", 
              "11/10", 
              "11/10", 
              "11/10"
            ],
          },
        ],
      },
      {
        name: CPU_LINUX_POWER89_LE,
        os: [
          {
            name: OS_RHEL,
            support: [
              "9/8/7",
              "9/8/7", 
              "9/8/7", 
              "9/8/7", 
              "9/8/7"
            ],
          },
          {
            name: OS_SLES,
            support: [
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
            ],
          },
        ],
      },
      {
        name: CPU_LINUX_ONE,
        os: [
          {
            name: OS_RHEL,
            support: [
              "9/8/7",
              "9/8/7", 
              "9/8/7", 
              "9/8/7", 
              "9/8/7"
            ],
          },
          {
            name: OS_SLES,
            support: [
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
            ],
          },
        ],
      },
      {
        name: CPU_WINDOWS_X86_64,
        os: [
          {
            //win 2019
            name: OS_WINDOWS_SERVER,
            support: [
              "2022", 
              "2022", 
              "2022", 
              "2022", 
              "2022"
            ],
          },
          {
            //win 2019
            name: OS_WINDOWS,
            support: [
              "11", 
              "11",  
              "11",  
              "11",  
              "11"
            ],
          },
        ],
      },
    ],
  },
  epas_extended: {
    versions: [
      {
        name: "16.x",
        generalAvailability: "Nov 9, 2023",
        supportEndDate: "Nov 9, 2028",
      },
      {
        name: "15.x",
        generalAvailability: "Feb 14, 2023",
        supportEndDate: "Feb 13, 2028",
      },
      {
        name: "14.x",
        generalAvailability: "Nov 18, 2021",
        supportEndDate: "Dec 1, 2026",
      },
      {
        name: "13.x",
        generalAvailability: "Nov 5, 2020",
        supportEndDate: "Nov 16, 2025",
      },
      {
        name: "12.x",
        generalAvailability: "June 01, 2020",
        supportEndDate: "Dec 9, 2024",
      },/*
      {
        name: "11.x",
        generalAvailability: "July 20, 2018",
        supportEndDate: "Nov 20, 2023",
      },*/
    ],
    platforms: [
      
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7", "9/8/7", "9/8/7", "9/8/7", "9/8/7"],
          },
          {
            name: OS_OL,
            support: ["9/8/7", "9/8/7", "9/8/7", "9/8/7", "9/8/7"],
          },
          {
            name: OS_ROCKY,
            support: ["9/8", "9/8", "9/8", "9/8", "9/8"],
          },
          {
            name: OS_CENT,
            support: ["7", "7", "7", "7", "7"],
          },
          {
            name: OS_SLES,
            support: [
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5"
            ],
          },
          {
            name: OS_UBUNTU,
            support: [
              "22.04/20.04",
              "22.04/20.04", 
              "22.04/20.04", 
              "22.04/20.04", 
              "22.04/20.04"
            ],
          },
          {
            name: OS_DEBIAN,
            support: [
              "11/10",
              "11/10", 
              "11/10", 
              "11/10", 
              "11/10"
            ],
          },
        ],
      },
            {
        name: CPU_LINUX_POWER89_LE,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7", "9/8", "9/8", "9/8", "9/8"],
          },
          {
            name: OS_SLES,
            support: [
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
            ],
          },
        ],
      },
    ],
  },
  pg: {
    versions: [
      {
        name: "16.x",
        generalAvailability: "Sep 26, 2023",
        supportEndDate: "Nov 9, 2028",
      }, 
      {
        name: "15.x",
        generalAvailability: "Oct 13, 2022",
        supportEndDate: "Nov 28, 2027",
      },      
      {
        name: "14.x",
        generalAvailability: "Sept 30, 2021",
        supportEndDate: "Dec 1, 2026",
      },
      {
        name: "13.x",
        generalAvailability: "Sept 24, 2020",
        supportEndDate: "Nov 16, 2025",
      },
      {
        name: "12.x",
        generalAvailability: "Oct 3, 2019",
        supportEndDate: "Dec 9, 2024",
      },
    ],
    platforms: [
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7","9/8/7", "9/8/7", "9/8/7", "9/8/7"],
          },
          {
            name: OS_OL,
            support: ["9/8/7","9/8/7", "9/8/7", "9/8/7", "9/8/7"],
          },
          {
            name: OS_ROCKY,
            support: ["9/8","9/8", "9/8", "9/8", "9/8"],
          },
          {
            name: OS_CENT,
            support: ["7","7", "7", "7", "7"],
          },
          {
            name: OS_SLES,
            support: [
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
            ],
          },
          {
            name: OS_UBUNTU,
            support: [
              "22.04/20.04",
              "22.04/20.04",
              "22.04/20.04",
              "22.04/20.04",
              "22.04/20.04",
            ],
          },
          {
            name: OS_DEBIAN,
            support: [
              "11/10",
              "11/10", 
              "11/10", 
              "11/10", 
              "11/10",
            ],
          },
        ],
      },
      {
        name: CPU_LINUX_POWER89_LE,
        os: [
          {
            name: OS_RHEL,
            support: [
              "9/8/7",
              "9/8/7", 
              "9/8/7", 
              "9/8/7", 
              "9/8/7",
            ],
          },
          {
            name: OS_SLES,
            support: [
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
            ],
          },
        ],
      },
      {
        name: CPU_WINDOWS_X86_64,
        os: [
          {
            //win 2019
            name: OS_WINDOWS_SERVER,
            support: [
              "2022", 
              "2022", 
              "2022", 
              "2022", 
              "2022", 
            ],
          },
          {
            name: OS_WINDOWS,
            support: [
              "11", 
              "11", 
              "11", 
              "11", 
              "11", 
            ],
          },
        ],
      },
      {
        name: OS_MAC,
        os: [
          {
            name: OS_MAC_X86,
            support: [
              "10.15+",
              "10.15+", 
              "10.14+", 
              "10.13+", 
              "10.13+", 
            ],
          },
          {
            name: OS_MAC_ARM,
            support: [
              "11.0+",
              "11.0+", 
              "11.0+", 
              "—", 
              "—", 
            ],
          },
        ],
      },
    ],
  },
  pgk8s: {
    versions: [
      {
        name: "1.21.x",
        generalAvailability: "Oct 18, 2023",
        supportEndDate: "Jun 18, 2024",
      },
      {
        name: "1.22.x (LTS)",
        generalAvailability: "Dec 22, 2023",
        supportEndDate: "May 21, 2025",
      },
      {
        name: "1.18.x (LTS)",
        generalAvailability: "Nov 10, 2022",
        supportEndDate: "Jun 12, 2024",
      },
    ],
    platforms: [
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: KUBE_K8S,
            support: ["1.26-1.28", "1.26-1.28", "1.27-1.28"],
          },
          {
            name: KUBE_RHOS,
            support: ["4.11-4.14", "4.12-4.14", "4.8-4.13"],
          },
        ],
      },
      {
        name: CPU_LINUX_POWER89_LE,
        os: [
          {
            name: KUBE_RHOS,
            support: ["4.12-4.14", "4.12-4.14", "4.8-4.13"],
          },

        ],
      },
    ],
  },
  
  bdrk8s: {
    versions: [
      {
        name: "1.0.x",
        generalAvailability: "Apr 24, 2024",
        supportEndDate: "18 months after the release of 2.0",
      },
    ],
    platforms: [
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: KUBE_K8S_nostar,
            support: ["1.26-1.29"],
          },
          {
            name: KUBE_RHOS_nostar,
            support: ["4.12-4.14"],
          },
        ],
      },
    ],
  },
  cnpg: {
    versions: [
      {
        name: "1.22.x",
        generalAvailability: "Dec 21, 2023",
        supportEndDate: "Aug 22, 2024",
      },
      {
        name: "1.21.x",
        generalAvailability: "Oct 18, 2023",
        supportEndDate: "Jun 18, 2024",
      },
    ],
    platforms: [
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: KUBE_K8S,
            support: ["1.26-1.28","1.26-1.28"],
          },
          {
            name: KUBE_RHOS,
            support: ["4.12-4.14", "4.12-4.14"],
          },
        ],
      },
    ],
  },
  bdr: {
    versions: [
      {
        name: "5.x",
        generalAvailability: "Feb 21, 2023",
        supportEndDate: "18 months after the release of 6.0",
      },
      {
        name: "4.x*",
        generalAvailability: "Dec 2, 2021",
        supportEndDate: "Feb 20, 2025",
      },
    ],
    platforms: [
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7", "9/8/7"],
          },
          {
            name: OS_OL,
            support: ["9/8/7", "9/8/7"],
          },
          {
            name: OS_ROCKY,
            support: ["9/8", "9/8"],
          },
          {
            name: OS_CENT,
            support: ["7", "7"],
          },
          {
            name: OS_SLES,
            support: [
              "15 SP5/12 SP5",
              "15 SP5/12 SP5",
            ],
          },
          {
            name: OS_UBUNTU,
            support: [
              "22.04/20.04", 
              "22.04/20.04", 
            ],
          },
          {
            name: OS_DEBIAN,
            support: ["11/10", "11/10"],
          },
        ],
      },
    ],
  },
  pem: {
    versions: [
      {
        name: "9.x",
        generalAvailability: "Dec 9, 2022",
        supportEndDate: "18 months after 10.0 release",
      },
      {
        name: "8.x",
        generalAvailability: "Sept 14, 2021",
        supportEndDate: "June 9, 2024",
      },
    ],
    platforms: [
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7", "9/8/7"],
          },
          {
            name: OS_OL,
            support: ["9/8/7", "9/8/7"],
          },
          {
            name: OS_ROCKY,
            support: ["9/8", "9/8"],
          },
          {
            name: OS_CENT,
            support: ["7", "7"],
          },
          {
            name: OS_SLES,
            support: ["15 SP5/12 SP5", "15 SP5/12 SP5"],
          },
          {
            name: OS_UBUNTU,
            support: ["22.04/20.04", "22.04/20.04"],
          },
          {
            name: OS_DEBIAN,
            support: ["11/10", "11/10"],
          },
        ],
      },
      {
        name: CPU_LINUX_POWER89_LE,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7", "9/8/7"],
          },
          {
            name: OS_SLES,
            support: ["15 SP5/12 SP5", "15 SP5/12 SP5"],
          },
        ],
      },
      {
        name: CPU_WINDOWS_X86_64,
        os: [
          {
            name: OS_WINDOWS_SERVER,
            support: ["2022", "2022"],
          },
          {
            //win 2019
            name: OS_WINDOWS,
            support: [
              "11", 
              "11"
            ],
          },
        ],
      },
      {
        name: "Browser Support",
        os: [
          {
            name: "Chrome",
            support: ["65+", "65+"],
          },
          {
            name: "Firefox",
            support: ["59+", "59+"],
          },
          {
            name: "Microsoft Edge",
            support: ["42+", "42+"],
          },
          {
            name: "Safari",
            support: ["11.1+", "11.1+"],
          },
        ],
      },
    ],
  },
  liveCompare: {
    versions: [
      {
        name: "2.x",
        generalAvailability: "Feb 15, 2022",
        supportEndDate: "18 months after 3.0 release",
      },
    ],
    platforms: [
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7"],
          },
          {
            name: OS_OL,
            support: ["9/8/7"],
          },
          {
            name: OS_ROCKY,
            support: ["9/8"],
          },
          {
            name: OS_CENT,
            support: ["7"],
          },
          {
            name: OS_UBUNTU,
            support: ["22.04/20.04"],
          },
          {
            name: OS_DEBIAN,
            support: ["11/10"],
          },
        ],
      },
      {
        name: CPU_LINUX_POWER89_LE,
        os: [
          {
            name: OS_RHEL,
            support: ["9"],
          },
        ],
      },
    ],
  },
  efm: {
    versions: [
      {
        name: "4.x",
        generalAvailability: "Sept 2, 2020",
        supportEndDate: "18 months after 5.0 release",
      },
    ],
    platforms: [
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7"],
          },
          {
            name: OS_OL,
            support: ["9/8/7"],
          },
          {
            name: OS_ROCKY,
            support: ["9/8"],
          },
          {
            name: OS_CENT,
            support: ["7"],
          },
          {
            name: OS_SLES,
            support: ["15 SP5/12 SP5"],
          },
          {
            name: OS_UBUNTU,
            support: ["22.04/20.04"],
          },
          {
            name: OS_DEBIAN,
            support: ["11/10"],
          },
        ],
      },
      {
        name: CPU_LINUX_POWER89_LE,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7"],
          },
          {
            name: OS_SLES,
            support: ["15 SP5/12 SP5"],
          },
        ],
      },
    ],
  },
  patroni: {
    versions: [
      {
        name: "3.x",
        generalAvailability: "Jan 30, 2023",
        supportEndDate: "18 months after 4.0 release",
      },
    ],
    platforms: [
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7"],
          },
          {
            name: OS_OL,
            support: ["9/8/7"],
          },
          {
            name: OS_ROCKY,
            support: ["9/8"],
          },
          {
            name: OS_CENT,
            support: ["7"],
          },
          {
            name: OS_SLES,
            support: ["15 SP5"],
          },
          {
            name: OS_UBUNTU,
            support: ["22.04/20.04"],
          },
          {
            name: OS_DEBIAN,
            support: ["11/10"],
          },
        ],
      },
      {
        name: CPU_LINUX_POWER89_LE,
        os: [
          {
            name: OS_RHEL,
            support: ["8/7"],
          },
        ],
      },
    ],
  },
  repmgr: {
    versions: [
      {
        name: "5.x",
        generalAvailability: "Oct 15, 2019",
        supportEndDate: "18 months after 6.0 release",
      },
    ],
    platforms: [
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: OS_RHEL,
            support: ["8/7"],
          },
          {
            name: OS_OL,
            support: ["8/7"],
          },
          {
            name: OS_ROCKY,
            support: ["8"],
          },
          {
            name: OS_CENT,
            support: ["7"],
          },
          {
            name: OS_UBUNTU,
            support: ["20.04"],
          },
          {
            name: OS_DEBIAN,
            support: ["11/10"],
          },
        ],
      },
    ],
  },
  /*harp: {
    versions: [
      {
        name: "2.x",
        generalAvailability: "Dec 1, 2021",
        supportEndDate: "Aug 20, 2024",
      },
    ],
    platforms: [
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: OS_RHEL,
            support: ["8/7"],
          },
          {
            name: OS_OL,
            support: ["8/7"],
          },
          {
            name: OS_ROCKY,
            support: ["8"],
          },
          {
            name: OS_CENT,
            support: ["7"],
          },
          {
            name: OS_UBUNTU,
            support: ["22.04/20.04"],
          },
          {
            name: OS_DEBIAN,
            support: ["11/10"],
          },
        ],
      },
    ],
  },*/
  barman: {
    versions: [
      {
        name: "3.x",
        generalAvailability: "June 27, 2022",
        supportEndDate: "18 months after 4.0 release",
      },
      {
        name: "2.x",
        generalAvailability: "Nov 3, 2020",
        supportEndDate: "December 27, 2023",
      },
    ],
    platforms: [
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7", "8/7"],
          },
          {
            name: OS_OL,
            support: ["9/8/7","8/7"],
          },
          {
            name: OS_ROCKY,
            support: ["9/8", "8"],
          },
          {
            name: OS_CENT,
            support: ["7", "7"],
          },
          {
            name: OS_SLES,
            support: ["15 SP5/12 SP5", "15 SP5/12 SP5"],
          },
          {
            name: OS_UBUNTU,
            support: ["22.04/20.04", "20.04"],
          },
          {
            name: OS_DEBIAN,
            support: ["11/10", "11/10"],
          },
        ],
      },
      {
        name: CPU_LINUX_POWER89_LE,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7", "8/7"],
          },
          {
            name: OS_SLES,
            support: ["15 SP5/12 SP5", "15 SP5/12 SP5"],
          },
        ],
      },
    ],
  },
  bart: {
    versions: [
      {
        name: "2.6",
        generalAvailability: "Oct 8, 2020",
        supportEndDate: "Nov 5, 2023",
      },
    ],
    platforms: [
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: OS_RHEL,
            support: ["8/7"],
          },
          {
            name: OS_OL,
            support: ["8/7"],
          },
          {
            name: OS_CENT,
            support: ["7"],
          },
          {
            name: OS_SLES,
            support: ["12 SP5"],
          },
          {
            name: OS_UBUNTU,
            support: ["20.04"],
          },
          {
            name: OS_DEBIAN,
            support: ["10"],
          },
        ],
      },
      {
        name: CPU_LINUX_POWER89_LE,
        os: [
          {
            name: OS_RHEL,
            support: ["7"],
          },
        ],
      },
    ],
  },
  eprs: {
    versions: [
      {
        name: "7.x",
        generalAvailability: "Dec 1, 2021",
        supportEndDate: "18 months after 8.0 release",
      },
    ],
    platforms: [
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7"],
          },
          {
            name: OS_OL,
            support: ["9/8/7"],
          },
          {
            name: OS_ROCKY,
            support: ["9/8"],
          },
          {
            name: OS_CENT,
            support: ["7"],
          },
          {
            name: OS_SLES,
            support: ["15 SP5/12 SP5"],
          },
          {
            name: OS_UBUNTU,
            support: ["22.04/20.04"],
          },
          {
            name: OS_DEBIAN,
            support: ["11/10"],
          },
        ],
      },
      {
        name: CPU_LINUX_POWER89_LE,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7"],
          },
          {
            name: OS_SLES,
            support: ["15 SP5/12 SP5",],
          },
        ],
      },
      {
        name: CPU_WINDOWS_X86_64,
        os: [
          {
            name: OS_WINDOWS_SERVER,
            support: ["2022"],
          },
        ],
      },
    ],
  },
  migrationToolkit: {
    versions: [
      {
        name: "55.x",
        generalAvailability: "July 20, 2021",
        supportEndDate: "18 months after 56.0 release",
      },
    ],
    platforms: [
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7"],
          },
          {
            name: OS_OL,
            support: ["9/8/7"],
          },
          {
            name: OS_ROCKY,
            support: ["9/8"],
          },
          {
            name: OS_CENT,
            support: ["7"],
          },
          {
            name: OS_SLES,
            support: ["15 SP5/12 SP5"],
          },
          {
            name: OS_UBUNTU,
            support: ["22.04/20.04"],
          },
          {
            name: OS_DEBIAN,
            support: ["11/10"],
          },
        ],
      },
      {
        name: CPU_LINUX_POWER89_LE,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7"],
          },
          {
            name: OS_SLES,
            support: ["15 SP5/12 SP5"],
          },
        ],
      },
      {
        name: CPU_WINDOWS_X86_64,
        os: [
          {
            //win 2019
            name: OS_WINDOWS_SERVER,
            support: ["2022"],
          },
        ],
      },
      {
        name: OS_MAC,
        os: [
          {
            name: OS_MAC,
            support: ["10.12+"],
          },
        ],
      },
    ],
  },
  tpa: {
    versions: [
      {
        name: "23.x",
        generalAvailability: "Jun 21, 2022",
        supportEndDate: "18 months after 24.0 release*",
      },
    ],
    platforms: [
      {
        name: CPU_LINUX_X86_64,
        os: [
          {
            name: OS_RHEL,
            support: ["9/8/7"],
          },
          {
            name: OS_OL,
            support: ["9/8/7"],
          },
          {
            name: OS_ROCKY,
            support: ["9/8"],

          },
          {
            name: OS_CENT,
            support: ["7"],
          },
          {
            name: OS_UBUNTU,
            support: ["22.04/20.04"],
          },
          {
            name: OS_DEBIAN,
            support: ["11/10"],
          },
          {
            name: OS_SLES,
            support: ["15 SP5"],
          },
        ],
      },
    ],
  },
};
