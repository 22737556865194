import React from "react"
import ModifiedGPLProducts from "../../components/products/ModifiedGPLProducts";
import Layout from "../../components/layout/Layout";
import Hero from "../../components/layout/page/Hero";
import PageSection from "../../components/layout/page/PageSection";
import { useOktaAuth } from '@okta/okta-react';

const ModifiedGPL = () => {
  const { authState } = useOktaAuth();

  let showAuthMsg = true;
  if (authState !== null && authState.isAuthenticated) {
    showAuthMsg = false;
  }
  return (
    <Layout>
      <Hero title="Modified GPL and Source Code Utilities" class="software-downloads-postgres" showAuth={showAuthMsg} />
      <PageSection>
        <ModifiedGPLProducts />
      </PageSection>
    </Layout>
  )
}

export default ModifiedGPL