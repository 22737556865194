import { useEffect } from "react";

const LegacyListPage = () => {
  useEffect(() => {
    window.location.href = "https://www.enterprisedb.com/docs/repos/migration/";
  }, []);
  return null;
};

export default LegacyListPage;
