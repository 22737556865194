import React, { useRef } from "react";
import config from "../../config";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { LinuxIcon, WindowsIcon, MacIcon } from "../icons/os_icons";
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch } from "react-redux";
import { setRedirectURL } from "../../store/redirectURLSlice";
import { authModalReceived } from "../../store/authModalSlice";
import { downloadThankYouPageURL } from '../../resources/StaticVars';
import AnchorLink from "../AnchorLink";
import { marketoPostData } from "../../client/edbApiClient";
import { email } from "../../resources/OktaSessionData";
import { anchorDisplay } from "../../utils";

interface ProductProps {
  product: {
    title: string;
    description: string;
    urlDocs: string;
    urlProductInfo: string;
    urlReleaseNotes: string;
    type: string;
  },
  versions: {
    title: string;
    field_installer_version: string;
    field_sub_version: string;
    field_os: string;
    field_installation_method: string;
    field_supported_os: string;
    uuid: string;
    field_show_this_version: string;
    field_campaign_id: string;
    field_product_category: string;
    field_asset_details: string;
  }[]
}

interface Product {
  field_installer_version: string;
  field_sub_version: string;
  field_os: string;
  field_installation_method: string;
  field_supported_os: string;
  uuid: string;
  field_show_this_version: string;
  field_campaign_id: string;
  field_product_category: string;
  field_asset_details: string;
}

interface ProductObject {
  [key: string]: Product[]
}

const ModifiedGPLProductDetails = React.forwardRef<HTMLLIElement, ProductProps>(( props, ref ) => {
  const anchorRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const edbWeb = config.edbWeb.baseUrl;
  const versions = props.versions;
  let versionTabLabels: ProductObject;
  let versionKeysDesc: string[] = [];
  
  const dispatch = useDispatch();

  const { authState } = useOktaAuth();

  if(!authState) return null

  const onClickUnauthenticatedProduct = (redirectUrl = "") => {
    dispatch(setRedirectURL(redirectUrl));
    dispatch(authModalReceived(true));
  };

  const handleMouseOver = () => {
    anchorRef.current.className = "visible position-relative anchor-link";
  }

  const handleMouseOut = () => {
    anchorRef.current.className = "invisible position-relative anchor-link";
  }

  const sendMarketoData = async (campaignId: string | null) => {
    if (campaignId === null) {
      campaignId = "";
    }

    // Pass campaign id to marketo.
    if (campaignId !== "") {
      await marketoPostData({
        "campaign_id": campaignId,
        "email": email
      });
    }
  }

  // Get Version numbers for tabs.
  if (Array.isArray(versions) && versions.length > 0) {
    versionTabLabels = versions.reduce((r: {[key: string]: []}, { 
      field_installer_version, 
      field_sub_version, 
      field_show_this_version,
      field_asset_details,
      field_campaign_id,
      field_os,
      field_product_category,
      field_supported_os,
      uuid
    }) => {
      if (field_show_this_version === "1") {
        const version = `${field_installer_version}.${field_sub_version}`;

        r[version] = r[version] || {
          field_installer_version, 
          field_sub_version, 
          field_asset_details, 
          field_campaign_id, 
          field_os, 
          field_product_category,
          field_supported_os,
          uuid 
        };
      }
      return r;
    }, {})
    
    const versionTabLabelsCopy = versionTabLabels;
    // Get descending order of string with multiple decimal points.
    // https://stackoverflow.com/questions/20080393/sorting-numbers-with-multiple-decimal-points
    versionKeysDesc = Object.keys(versionTabLabelsCopy).sort(function(a: string, b: string) {
      const nums1 = a.split(".") as [];
      const nums2 = b.split(".") as [];
  
      for (let i = 0; i < nums1.length; i++) {
          if (nums2[i]) { // assuming 5..2 is invalid
              if (nums1[i] !== nums2[i]) {
                 return nums1[i] - nums2[i];   
              } // else continue
          } else {
              return 1; // no second number in b
          }
      }
      return -1; // was missing case b.len > a.len
    });

    versionKeysDesc = versionKeysDesc.reverse();
  }

  const anchorLinkStyles = {
    position: 'absolute',
    left: '-30px',
    top: '-30px',
    paddingLeft: '0px'
  }

  if (versionKeysDesc.length > 0) {
    return (
      <>
        <li 
          key={props.product.title} 
          className="flexible p-4 mb-4 shadow-sm product-list-item" 
          id={anchorDisplay(props.product.type, '/software-downloads-postgres')} 
          ref={ref}
        >
          <div className="product-overview">
            <h2 onMouseOut={handleMouseOut} onMouseOver={handleMouseOver}>
              {props.product.title}
              <AnchorLink 
                url={`${config.edbWeb.baseUrl}/software-downloads-postgres`} 
                style={anchorLinkStyles} 
                ref={anchorRef} 
                anchorType={props.product.type}
                path={'/software-downloads-postgres'}
              />
            </h2>
            <p>{props.product.description}</p>
            <ul className="list-unstyled">
              {props.product.urlDocs !== "" && (
                <li>
                  <a href={`${edbWeb}${props.product.urlDocs}`} className="text-decoration-none">Docs</a>
                </li>
              )}
              {props.product.urlProductInfo !== "" && (
                <li>
                  <a href={`${edbWeb}${props.product.urlProductInfo}`} className="text-decoration-none">Product Information</a>
                </li>
              )}
              {props.product.urlReleaseNotes !== "" && (
                <li>
                  <a href={`${edbWeb}${props.product.urlReleaseNotes}`} className="text-decoration-none">Release notes</a>
                </li>
              )}
            </ul>
          </div>
          <div className="product-versions w-100">
            <Tabs
              id="versions-tab"
              variant="pills"
            >
              {versionKeysDesc.map((version) => {
                    let filteredByOs = versions.filter(function (el) {
                      return `${el.field_installer_version}.${el.field_sub_version}` === version
                    })

                    // Sort by field_os Linux/Mac/Windows
                    filteredByOs = props.product.type !== "jdbsDrivers" && props.product.type !== "toolkit" ?
                     filteredByOs.sort((a, b) => (a.field_os > b.field_os) ? 1 : -1) : filteredByOs;

                    // Sort alphabetically.
                    filteredByOs = props.product.type !== "jdbsDrivers" && props.product.type !== "toolkit" ? 
                      filteredByOs.sort((a, b) => (a.field_os > b.field_os ? 1 : -1)) : filteredByOs;

                return (
                  <Tab eventKey={version} title={version.replace(/\.$/, "").replace("zero", "0")} key={version}>
                    <ul>
                      {filteredByOs.map((version) => {
                        let softwareLinkLabel = "Access repo →";
                        let softwareLink = "";
                        if (version.field_installation_method === "Interactive Installer") {
                          softwareLinkLabel = "Download →";
                          softwareLink = `${downloadThankYouPageURL}?uuid=${version.uuid}&campaignId=${version.field_campaign_id}`;
                        }
                        else {
                          switch (version.field_product_category) {
                            case 'Trusted Postgres Architect':
                              softwareLinkLabel = "Get Repos 2.0 Token →";
                              softwareLink = `/repos-downloads${
                                version.field_campaign_id
                                  ? `?campaignId=${version.field_campaign_id}`
                                  : ""
                              }`
                              break;
                            case 'EDB Postgres Distributed':
                              softwareLinkLabel = "Install with TPA →";
                              softwareLink = "https://www.enterprisedb.com/docs/pgd/latest/tpa/";
                              break;
                            default:
                              softwareLinkLabel = "Access repo →";
                              softwareLink = `/repos-downloads${
                                version.field_campaign_id
                                  ? `?campaignId=${version.field_campaign_id}`
                                  : ""
                              }`
                          }
                        }

                        return (
                          <li key={version.uuid} className="d-flex justify-content-between border-bottom mb-3 pb-3">
                            <div className="sub-versions-wrapper">
                              {version.field_product_category !== "Modified GPL and Source Code Utilities" && 
                              version.field_product_category !== "Third Party JDBC Drivers" ? (
                                <div className="d-flex">
                                  <div className="os-icon">
                                    {version.field_os.split(" ")[0] === "Linux" ? <LinuxIcon />
                                    : version.field_os.split(" ")[0] === "Windows" ? <WindowsIcon /> 
                                    : <MacIcon />}
                                    
                                  </div>
                                  <div>
                                    <div>{version.field_os}</div>
                                    <div>{version.field_installation_method}</div>
                                    {version.field_asset_details !== "" && (
                                      <div>{version.field_asset_details}</div>
                                    )}
                                  </div>
                                </div>
                              ) :
                              (
                                <>
                                  {version.field_product_category === "Third Party JDBC Drivers" && (
                                    <div>{version.field_installation_method}</div>
                                  )}
                                  <div>{version.field_asset_details}</div>
                                </>
                              )}
                            </div>
                            <div>
                              {
                                version.field_installation_method === "Interactive Installer" ? (
                                  !authState.isAuthenticated ? (
                                    <button
                                    className="btn btn-link text-decoration-none installer-btn"
                                    onClick={() =>
                                        onClickUnauthenticatedProduct(
                                          `${location.origin}${softwareLink}`,
                                        )
                                      }
                                      >
                                      {softwareLinkLabel}
                                    </button>
                                  )
                                  :
                                  (
                                    <a
                                      className="btn btn-link text-decoration-none installer-btn"
                                      href={softwareLink}
                                    >
                                      {softwareLinkLabel}
                                    </a>
                                  )
                                ) :
                                (
                                  !authState.isAuthenticated ? (
                                    <button
                                    className="btn btn-link text-decoration-none installer-btn"
                                    onClick={() =>
                                        onClickUnauthenticatedProduct(
                                          version.field_product_category === "EDB Postgres Distributed" ? `${softwareLink}` :
                                          `${location.origin}${softwareLink}`
                                        )
                                      }
                                      >
                                      {softwareLinkLabel}
                                    </button>
                                  )
                                  :
                                  (
                                    <a
                                      className="btn btn-link text-decoration-none installer-btn"
                                      href={softwareLink}
                                      onClick={() => {
                                        if (version.field_product_category === "EDB Postgres Distributed") {
                                          const campaignId = version.field_campaign_id;
                                          // Pass campaign id to marketo.
                                          sendMarketoData(campaignId);
                                        }
                                      }}
                                    >
                                      {softwareLinkLabel}
                                    </a>
                                  )
                                )
                              }
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </Tab>
                )
              })}
            </Tabs>
          </div>
        </li>
      </>  
    )
  }
  else {
    return (
      <>
      </>
    )
  }
})

export default ModifiedGPLProductDetails