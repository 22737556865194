//import styles from '../nav/styles.module.scss';

export const ChevronDown = () => {
  return (
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="1em" height="1em" data-type="chevron"><path d="M 24.990234 8.9863281 A 1.0001 1.0001 0 0 0 24.292969 9.2929688 L 15 18.585938 L 5.7070312 9.2929688 A 1.0001 1.0001 0 0 0 4.9902344 8.9902344 A 1.0001 1.0001 0 0 0 4.2929688 10.707031 L 14.292969 20.707031 A 1.0001 1.0001 0 0 0 15.707031 20.707031 L 25.707031 10.707031 A 1.0001 1.0001 0 0 0 24.990234 8.9863281 z"/></svg>
  )
}

export const ChevronRight = () => {
  return (
    <svg viewBox="0 0 30 30" width="1em" height="1em" fill="currentColor"><path d="M9.99 3.99a1 1 0 00-.697 1.717L18.586 15l-9.293 9.293a1 1 0 101.414 1.414l10-10a1 1 0 000-1.414l-10-10a1 1 0 00-.717-.303z"></path></svg>
  )
}

export const ChevronUp = () => {
  // Need to get another svg instead of reversing with css because messing up menu on platform compatibility page.
  return (
    <svg fill="currentColor" className="chevron-up" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="1em" height="1em" data-type="chevron"><path d="M 24.990234 8.9863281 A 1.0001 1.0001 0 0 0 24.292969 9.2929688 L 15 18.585938 L 5.7070312 9.2929688 A 1.0001 1.0001 0 0 0 4.9902344 8.9902344 A 1.0001 1.0001 0 0 0 4.2929688 10.707031 L 14.292969 20.707031 A 1.0001 1.0001 0 0 0 15.707031 20.707031 L 25.707031 10.707031 A 1.0001 1.0001 0 0 0 24.990234 8.9863281 z"/></svg>
  )
}