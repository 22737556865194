import React from "react";
import SelectPlaftorm from "../../repos/SelectPlatform";
import SelectSoftware from "../../repos/SelectSoftware";
import ReposInstructions from "../../repos/ReposInstructions";

interface PageProps {
  page: string;
  repoType: string;
}

const ReposListPageLayout = ( props: PageProps ) => {
  return (
    <div className="bg-light px-3-mobile container mobile-container">
      <div className="repo-label-wrapper pt-5">
        <h2>Install EDB software using your system package manager</h2>
        <p>Select a platform and package to view instructions.</p>
      </div>
      
      <div className="repo-select-wrapper d-flex ">
        
        <div className="repo-choices mr-2 w-30">
          <div className="mt-4 me-4-mobile mb-4">
            <SelectPlaftorm />
          </div>
          <div className="mt-4 me-4-mobile mb-4">
            <SelectSoftware repoType={props.repoType} />
          </div>
        </div>
        <div className="instructions-wrapper w-75 m-4">
          <ReposInstructions repoType={props.repoType} />
        </div>
    </div>
  </div>
  )
}

export default ReposListPageLayout;