import { createSlice } from "@reduxjs/toolkit";

export const reposAccessSlice = createSlice({
  name: 'reposAccess',
  initialState: {
    access: {},
    loading: false,
  },
  reducers: {
    reposAccessRequested: (reposAccess) => {
      reposAccess.loading = true;
    },
    reposAccessReceived: (reposAccess, action) => {
      reposAccess.access = action.payload;
      reposAccess.loading = false;
    },
    reposAccessRequestFailed: (reposAccess) => {
      reposAccess.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function.
export const { reposAccessRequested, reposAccessReceived, reposAccessRequestFailed } = reposAccessSlice.actions;
export default reposAccessSlice.reducer;