import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ProductsByOs, InstructionsReplacements } from "../../resources/Types";
import { instructionsReplacementsApi } from "../../client/edbApiClient";
import { 
  instructionsReplacementReceived, 
  instructionsReplacementRequested, 
  instructionsReplacementAddPackage,
  instructionsReplacementRemovePackage,
  instructionsReplacementRemoveAllPackages
} from "../../store/instructionsReplacementSlice";
import { legacyVersionReceived } from "../../store/legacyIdsSlice";

interface productsByOsState {
  productsByOs: ProductsByOs;
}

interface ReposInstructionsProps {
  repoType: string;
}

interface Product {
  id?: number;
  name?: string | null;
  versions: [
    {
      id?: number | null;
      name?: string | null;
      distributable?: {
        id?: number | null;
        name?: string | null;
      }
    }
  ],
  distributables?: [],
  subscriptions?: [
    {
      id?: number | null;
      name?: string | null;
    }
  ],
  userAcccess?: boolean | null;
  template?: string | null;
  distro: string;
  code_name: string;
}

interface Version {
  id?: number | null;
  name?: string | null;
  distributable?: {
    id?: number | null;
    name?: string | null;
  }
}

const SelectSoftware = ( props: ReposInstructionsProps ) => {
  const productsByOs = useSelector((state: productsByOsState) => state.productsByOs);
  const dispatch = useDispatch();
  const repoType = props.repoType;
  let products: ProductsByOs | unknown = [];
  if (typeof productsByOs === "object"
   && Object.keys(productsByOs).length > 0 
   && 'productsByOs' in productsByOs
   && typeof productsByOs.productsByOs !== "undefined"
   && 'data' in productsByOs.productsByOs) {
    products = productsByOs.productsByOs.data.products
  }
  const productsLength = Array.isArray(products) ? products.length : 0;
  const softwareCheckboxRef = useRef(new Array(productsLength));
  
  // Get Instructions.
  const handleChange = ( event: React.ChangeEvent<HTMLInputElement>, 
    distributableName: string, 
    distro: string, 
    codeName: string,
    versionId: number ) => {
    const checkedStatus = event.target.checked;
    const currentVersionName = event.target.name;
    // Save selected version id to the store.
    dispatch(legacyVersionReceived(versionId));

    // Get instructions replacements.
    fetchInstructionsReplacements(checkedStatus, distributableName, distro, codeName, currentVersionName, versionId);
  }

  const fetchInstructionsReplacements = async (
    checkedStatus: boolean, 
    packageName: string, 
    distro: string, 
    codeName: string, 
    currentVersionName: string,
    versionId: number,
  ) => {
    // Logic to uncheck same product if checking a different version of the same product.
    const namesChecked: string[] = [];
    softwareCheckboxRef.current.map((v: HTMLInputElement) => {
      if (v !== null && 'checked' in v && v.checked) {
        namesChecked.push(v.name);
      }
    });

    const numTimes = namesChecked.filter(name => name === currentVersionName).length;
    
    if (namesChecked.length > 1 && numTimes > 1) {
      softwareCheckboxRef.current.map((v: HTMLInputElement) => {
        if (v !== null && v.checked) {
          if (v.name === currentVersionName) {
            // Uncheck previous version checkbox.
            v.checked = false;
            // Remove package from store.
            dispatch(instructionsReplacementRemovePackage(v.getAttribute("data-packagename")));
          }
        }
      });
    }

    if (namesChecked.length > 1 && numTimes === 0) {
      softwareCheckboxRef.current.map((v: HTMLInputElement) => {
        if (v.checked) {
          // Remove package because all versions for product unchecked.
          dispatch(instructionsReplacementRemovePackage(packageName));
        }
      });
    }

    // Remove all packages from store if nothing checked.
    if (namesChecked.length === 0) {
      dispatch(instructionsReplacementRemoveAllPackages());
    }

    // Only allow one checkbox checked at a time on legacy page.
    if (repoType === "legacy") {
      softwareCheckboxRef.current.map((v: HTMLInputElement) => {
        if (v !== null && 'name' in v && v.name !== currentVersionName) {
          v.checked = false;
        }
      });
    }

    // Make sure if originally checking the current version
    // that it remains checked.
    if (checkedStatus && 
      softwareCheckboxRef.current.length > 0 &&
      softwareCheckboxRef.current[versionId] !== null && 
      'checked' in softwareCheckboxRef.current[versionId]) {
      softwareCheckboxRef.current[versionId].checked = true;
    }

    // Set instructionsReplacement loading to true.
    // to show the spinner on the instructions component.

    // Package checked so add package to for package_names in instructions.
    if (checkedStatus) {
      dispatch(instructionsReplacementAddPackage(packageName));
    }
    // Package unchecked so remove package for package_names in instructions.
    else {
      dispatch(instructionsReplacementRemovePackage(packageName));
    }
    dispatch(instructionsReplacementRequested());
    return await instructionsReplacementsApi(packageName, distro, codeName)
    .then((res: InstructionsReplacements | unknown) => {
      // Save to the redux store.
      dispatch(instructionsReplacementReceived(res));
    })
  }

  return (
    <div className="card shadow p-3 mb-5 bg-body rounded">
      <div className="card-body">
        <h5>Select software:</h5>
        {typeof products === "undefined" || (Array.isArray(products) && products.length === 0) && (
          <p>
            Select a platform above to see the available software
          </p>
        )}
        {Array.isArray(products) && products.length > 0 && (
          <ul className="list-unstyled">
            {products.map((product: Product) => (
              <li
                key={product.id}
              >
                {product.name}
                {'versions' in product && product.versions.length && (
                  <ul className="list-unstyled">
                    {typeof product === "object" && 'versions' in product && product.versions.map((version: Product | Version) => (
                      <li key={version.id}>
                        <input 
                          className="form-check-input ms-4" 
                          type={'checkbox'} 
                          name={`product-${product.id}`} 
                          value={typeof version.id === "number" ? version.id : ''} 
                          data-packagename={'distributable' in version && typeof version.distributable !== "undefined" &&  'name' in version.distributable ? version.distributable.name : ''}
                          disabled={product.userAcccess ? false : true}
                          ref={(element) => typeof version.id === "number" ? softwareCheckboxRef.current[version.id] = element : ''}
                          onChange={(event) => {
                            const distributableName = 'distributable' in version && typeof version.distributable !== "undefined" &&  'name' in version.distributable ? version.distributable.name : '';
                            if (typeof distributableName === "string" && typeof version.id === "number") {
                              handleChange(
                                event,
                                distributableName,
                                product.distro,
                                product.code_name,
                                version.id,
                              )
                              }
                            }
                          }
                        />
                        <label className="form-check-label ms-2">
                          {version.name}
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default SelectSoftware;