import HeaderStructure from "./HeaderStructure";
import HeaderBanner from "./HeaderBanner";

const Header = () => {

  return (
    <>
        <>
          <HeaderStructure />
          <HeaderBanner />
        </>
      
    </> 
  )
}

export default Header;