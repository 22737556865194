import axios, { AxiosResponse } from "axios";
import config from "../config";
import { accessToken } from "../resources/OktaSessionData";

const edbApiInstance = axios.create({
  baseURL: config.edbApi.baseUrl,
  timeout: 30000,
});

const responseBody = (response: AxiosResponse) => response.data

const downloadInstallerRequest = {
  get: (url: string) => edbApiInstance.get<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
  }).then(responseBody),
}

export const DownloadInstallerApi = {
  getDownloadInstaller : (uuid: string, campaignId: string) : Promise<string> => downloadInstallerRequest.get(`/api/download/installer-dw/${uuid}/${campaignId}`),
}