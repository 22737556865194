import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';
import { deleteOktaCookies } from "../utils"

const Logout = () => {

    //const navigate = useNavigate();

    const { oktaAuth, authState } = useOktaAuth() || {};
    
    useEffect(() => {
       const logoutUser = async() => {
        deleteOktaCookies();
        await oktaAuth.signOut();
        //navigate("/") 
       }
       logoutUser();
    }, []);
   
    return <></>;
};

export default Logout;