import ButtonEDB from "./ButtonEdb";
import { useSelector, useDispatch } from "react-redux";
import { redirectURLState } from "../resources/Types";
import { useEffect } from "react";
import { setRedirectURL } from "../store/redirectURLSlice";
import { loginLink, registerLink, redirectParam } from "../resources/StaticVars";

const AuthMessage = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    //set the current page url in the redirectURL state
    dispatch(setRedirectURL(window.location.href));
  }, []);

  const redirectURL = useSelector((state: redirectURLState) => state.redirectURL);
  
  return (
    <div className="auth-message">
      <span>
        You'll need an EDB account to access our software repositories:
      </span>
      <ButtonEDB 
        label="Sign In" 
        color="white" 
        href={`${loginLink}?${redirectParam}=${redirectURL.url}`}
        btnClass="m-3" 
      />
      <ButtonEDB 
        label="Create New Account" 
        color="white" 
        type="secondary"
        href={`${registerLink}?${redirectParam}=${redirectURL.url}`}
      />
    </div>
  )
}

export default AuthMessage;