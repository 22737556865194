import { OktaSession } from "./Types";
import { hasCookie, getCookie } from 'cookies-next';
import config from "../config"

function getSessionData() {
  // Get Okta Session.
  const oktaSession: OktaSession | unknown = sessionStorage.getItem("okta-token-storage");
  let accessToken = "";
  let fullName = "";
  let username = "";
  let email = "";
  
  if (typeof oktaSession === "string") {
    const oktaSessionJson: OktaSession = JSON.parse(oktaSession);
    if (typeof oktaSessionJson !== "undefined") {
      // accessToken data.
      if ('accessToken' in oktaSessionJson) {
        const accessTokenObj = oktaSessionJson.accessToken;
        // Get Access Token.
        if (typeof accessTokenObj !== "undefined" && 'accessToken' in accessTokenObj) {
          accessToken = accessTokenObj.accessToken;
        }
      }

      // idToken data.
      if ('idToken' in oktaSessionJson) {
        const idToken = oktaSessionJson.idToken;
        if (typeof idToken !== "undefined" && 'claims' in idToken) {
          const claims = idToken.claims;
          if (typeof claims !== "undefined") {
            // Get Fullname.
            if ('name' in claims && typeof claims.name !== "undefined") {
              fullName = claims.name;
            }
            // Get Username.
            if ('preferred_username' in claims && typeof claims.preferred_username !== "undefined") {
              username = claims.preferred_username;
            }
            // Get email.
            if ('email' in claims && typeof claims.email !== "undefined") {
              email = claims.email;
            }
          }
        }
      }
    }
  }

  return {
    accessToken: accessToken,
    fullName: fullName,
    username: username,
    email: email
  }
}


function getCookiesData() {

  // Get Okta Session.
  //const oktaSession: OktaSession | unknown = sessionStorage.getItem("okta-token-storage");
  let accessToken = "";
  let fullName = "";
  let username = "";
  let email = "";
  
  if (hasCookie('okta-token-storage_accessToken')) {

    const oktaCookie: any = getCookie('okta-token-storage_accessToken')
    const oktaCookieJson: any = JSON.parse(oktaCookie);

    if (typeof oktaCookieJson !== "undefined") {
      // accessToken data.
      if ('accessToken' in oktaCookieJson) {
        accessToken = oktaCookieJson.accessToken;
      }
      

      const oktaIDCookie: any = getCookie('okta-token-storage_idToken')
      if (typeof oktaIDCookie === "undefined") {
        return {
          accessToken: "",
          fullName: "",
          username: "",
          email: ""
        }
      }
      const oktaIDCookieJson: any = JSON.parse(oktaIDCookie);
      
      if (typeof oktaIDCookieJson !== "undefined" && 'claims' in oktaIDCookieJson) {
        const claims = oktaIDCookieJson.claims;

        if (typeof claims !== "undefined") {
          // Get Fullname.
          if ('name' in claims && typeof claims.name !== "undefined") {
            fullName = claims.name;
          }
          // Get Username.
          if ('preferred_username' in claims && typeof claims.preferred_username !== "undefined") {
            username = claims.preferred_username;
          }
          // Get email.
          if ('email' in claims && typeof claims.email !== "undefined") {
            email = claims.email;
          }
        }
      }
    }
  }

  return {
    accessToken: accessToken,
    fullName: fullName,
    username: username,
    email: email
  }
}

export const {
  accessToken,
  fullName,
  username,
  email
} = (config.okta.tokenManager.storage == 'sessionStorage') ? getSessionData() : getCookiesData()