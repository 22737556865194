import CompatibilityTable from "./CompatibilityTable";

interface PlatformProps {
  title: string;
  products: string[];
}

const Platform = (props: PlatformProps) => {
  
  return (
    <div className="mb-5 container compatiblity-content">
      <h5 className="mb-4">
        {props.title}
      </h5>
      <CompatibilityTable 
        products={props.products}
      />
    </div>
  )
}

export default Platform;