'use client'
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { Triangle } from '../../icons/triangle';
import { ChevronDown, ChevronUp } from '../../icons/chevrons';
import { hasCookie } from 'cookies-next';

const SignIn = () => {
  const [auth, setAuth] = useState(false);
  const signinWrapperRef = useRef<HTMLUListElement | null>(null);
  const signInSpanRef = useRef<HTMLSpanElement | null>(null);
  const chevronDownRef = useRef<HTMLSpanElement | null>(null);
  const chevronUpRef = useRef<HTMLSpanElement | null>(null);

  const handleSigninClickOutside = useCallback((e: any) => {
    if (e.target.className !== `${styles.websiteDarkBtn}` && 
    e.target.dataset.spanName !== 'signIn' && 
    e.target.className !== `${styles.chevronDownSigninClickedVisible}` && 
    e.target.dataset.spanName !== 'myAccount' && 
    e.target.dataset.type !== 'chevron') {
      if (signinWrapperRef.current !== null) {
        signinWrapperRef.current.className = styles.signinWrapper;
        if (chevronUpRef.current !== null && chevronDownRef.current !== null) {
          chevronUpRef.current.className = !auth ? styles.chevronUpSignInHidden : styles.chevronUpMyAccountHidden;
          chevronDownRef.current.className = !auth ? styles.chevronDownSigninClickedVisible : styles.chevronDownMyAccountVisible;
        }
        if (signInSpanRef.current !== null) {
          signInSpanRef.current.className = 'inline-block align-middle pe-2 mr-[15px]';
        }
      }
    }
  }, [auth])

  useEffect(() => {
    if (hasCookie('okta-token-storage_accessToken')) {
      setAuth(true);
    }
    else {
      setAuth(false);
    }

    document.addEventListener("click", handleSigninClickOutside, false);
    return () => {
      document.removeEventListener("click", handleSigninClickOutside, false);
    };
  }, [handleSigninClickOutside])
  const handleClick = () => {
    if (signinWrapperRef.current !== null && signinWrapperRef.current?.className !== styles.signinWrapperOpened) {
      signinWrapperRef.current.className = styles.signinWrapperOpened;
      if (chevronDownRef.current !== null && chevronUpRef.current !== null) {
        chevronDownRef.current.className = !auth ? styles.chevronDownSigninHidden : styles.chevronDownMyAccountHidden;
        chevronUpRef.current.className = !auth ? styles.chevronUpSignInClickedVisible : styles.chevronUpMyAccountVisible;
      }
      if (signInSpanRef.current !== null) {
        signInSpanRef.current.className = 'inline-block align-middle pe-2 mr-[15px]';
      }
    }
    else if (signinWrapperRef.current !== null) {
      signinWrapperRef.current.className = styles.signinWrapper;
      if (chevronUpRef.current !== null && chevronDownRef.current !== null) {
        chevronUpRef.current.className = !auth ? styles.chevronUpSignInHidden : styles.chevronUpMyAccountHidden;
        chevronDownRef.current.className = !auth ? styles.chevronDownSigninClickedVisible : styles.chevronDownMyAccountVisible;
      }
      if (signInSpanRef.current !== null) {
        signInSpanRef.current.className = 'inline-block align-middle pe-2 mr-[15px]';
      }
    }
  }

  return (
    <div className={styles.loginLinkWrapper}>
      <ul className={styles.loginLinkItems} id="loginLinkItemsId">

        {!auth ? (
          <li className='relative signinbutton'>
            <span 
              className={styles.websiteClearBtn}
              onClick={handleClick}
            >
              <span className='inline-block align-middle pe-2' ref={signInSpanRef} data-span-name='signIn'>
                Sign In
              </span>
              <span className='inline-block align-middle relative chevron-wrapper'>
                <span className={styles.chevronDownSignInVisible} ref={chevronDownRef}>
                  <ChevronDown />
                </span>
                <span className={styles.chevronUpSignInHidden} ref={chevronUpRef}>
                  <ChevronUp />
                </span>
              </span>
            </span>
            <ul className={styles.signinWrapper} ref={signinWrapperRef}>
            <span 
              className={`${styles.signInArrow}`}
            >
              <Triangle />
            </span>
              <li>
                <a 
                  href="/accounts/login" 
                  key="login"
                >
                  <span className='ps-2 inline-block align-middle'>
                    EDB Login
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="http://freetrial.biganimal.com/"
                >
                  <span className='ps-2 inline-block align-middle'>
                    EDB Postgres AI Login
                  </span>
                </a>
              </li>
            </ul>
          </li>
        )
        : (
          <li className='relative signinbutton mr-2'>
          <span 
            className={styles.websiteClearBtn}
            onClick={handleClick}
          >
            <span className='inline-block align-middle pe-2' data-span-name='myAccount'>
              My Account
            </span>
            <span className='inline-block align-middle chevron-wrapper'>
              <span className={styles.chevronDownMyAccountVisible} ref={chevronDownRef}>
                <ChevronDown />
              </span>
              <span className={styles.chevronUpMyAccountHidden} ref={chevronUpRef}>
                <ChevronUp />
              </span>
            </span>
          </span>
          <ul className={styles.signinWrapper} ref={signinWrapperRef}>
          <span 
            className={`${styles.signInArrow}`}
          >
            <Triangle />
          </span>
            <li>
              <a 
                href="/dashboard" 
              >
                Dashboard
              </a>
            </li>
            <li>
              <a
                href="http://freetrial.biganimal.com/"
              >
                EDB Postgres AI Cloud <span className='ps-2'>Service</span>
              </a>
            </li>
            <li>
              <a
                href="https://techsupport.enterprisedb.com/home"
              >
                Support Portal
              </a>
            </li>
            <li>
              <a
                href="https://training.enterprisedb.com/"
              >
                Training Portal
              </a>
            </li>
            <li>
              <a
                href="https://migration.enterprisedb.com/"
              >
                Migration Portal
              </a>
            </li>
            <li>
              <a
                href="https://enterprisedbpartners.force.com/EDBPartners"
              >
                Partner Portal
              </a>
            </li>
            <li>
              <a
                href="/accounts/profile"
              >
                Account Settings
              </a>
            </li>
            <li>
              <a
                href="/accounts/logout"
              >
                Sign Out
              </a>
            </li>
          </ul>
        </li>
        )}
        <li className='relative mr-3 talktoexpert'>
          <a 
            href="https://info.enterprisedb.com/EDB-Contact-Us.html"
            className={`${styles.websiteGoldBtnOutline} mr-2`}
          >
            Talk to an Expert
          </a>
        </li>
      </ul>
    </div>
  )
}

export default SignIn