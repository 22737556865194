import styles from './styles.module.scss';

const HeaderBanner = () => {
  // Hidden if not showing a press release.  Remove hidden if showing.
  return (
    <div className={`${styles.pressReleaseWrapper} hidden`}>
      <div className="text-center">
        <strong className={styles.pressReleaseLabel}>Press Release</strong>
        <span className={styles.pressReleaseTitle}>
          EDB Introduces Two New Ways to Get Postgres in AWS Marketplace
        </span>
        <strong className={styles.pressReleaseLink}>
          <a href="https://www.enterprisedb.com/news/EDB-introduces-postgres-in-AWS-marketplace" target="_self">
            Read More
          </a>
        </strong>
      </div>
    </div>
  )
}

export default HeaderBanner