import React from "react";
import config from "../../config";
import { fullName } from "../../resources/OktaSessionData";

const SignedInAs = () => {
  return (
    <span className="text-secondary h6 text-uppercase">
      Signed in as 
      <a 
        href={`${config.edbWeb.baseUrl}/accounts/profile`} 
        className="ms-2 text-white"
      >
        {fullName}
      </a>
    </span>
  )
}

export default SignedInAs;