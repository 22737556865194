import React, {useRef } from 'react';
import { SearchIconDark, SearchCancelIcon } from "../../icons/search";
import styles from './styles.module.scss';

interface SearchProps {
  sendDataToParent: any;
}

const SearchButton = ( props: SearchProps ) => {
  const searchIconRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const searchCancelIconRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const sendDataToParent = props.sendDataToParent;

  const handleSearchClick = () => {

    if (searchIconRef.current.className.includes("hidden")) {
      // No longer showing search input so show search icon.
      searchIconRef.current.className = searchIconRef.current.className.replace("hidden", "block");
    }
    else {
      // Searching (showing search input box) so hiding search icon.
      searchIconRef.current.className = searchIconRef.current.className.replace("block", "hidden");
    }

    if (searchCancelIconRef.current.className.includes("hidden")) {
      // Show cancel button because we are searching (showing search input box)
      searchCancelIconRef.current.className = searchCancelIconRef.current.className.replace("hidden", "block");
      sendDataToParent("inline", "hidden");
    }
    else {
      // Hide cancel button because we are no longer searching (search input box is hidden)
      searchCancelIconRef.current.className = searchCancelIconRef.current.className.replace("block", "hidden");
      sendDataToParent("hidden", "inline");
    }
  }

  return (
    <div className={styles.searchNavWrapper}>
      <div 
        className={styles.searchIconWrapper}
        onClick={handleSearchClick}
      >
        <span ref={searchIconRef} className='block'>
          <SearchIconDark />
        </span>
        <span ref={searchCancelIconRef} className='hidden'>
          <SearchCancelIcon />
        </span>
      </div>
    </div>
  )
}

export default SearchButton