import config from "../config";

const HelpfulResources = () => {
  const edbWeb = config.edbWeb.baseUrl;

  return (
    <div className="py-5">
      <div className="py-5">
        <h2 className="h4">
          Helpful Resources
        </h2>
        <div className="card shadow py-5 ps-5 mb-5 bg-body rounded">
          <a href={`${edbWeb}/docs`} className="text-decoration-none">
            <h4 className="h5 fw-bold">
              Docs
            </h4>
          </a>
          <p className="pb-2">
            For all EDB products and versions
          </p>
          <a href={`${edbWeb}/postgres-tutorials`} className="text-decoration-none">
            <h4 className="h5 fw-bold">
              Postgres Tutorials
            </h4>
          </a>
          <p className="pb-2">
            Learn tricks to make you successful with Postgres
          </p>
          <a href={`${edbWeb}/training/free-postgres-training`} className="text-decoration-none">
            <h4 className="h5 fw-bold">
              Postgres Training and Certification
            </h4>
          </a>
          <p className="pb-2">
            Get free training or enroll in our certification program
          </p>
        </div>
      </div>
    </div>
  )
}

export default HelpfulResources;