import { createSlice } from "@reduxjs/toolkit";

export const operatingSystemsSlice = createSlice({
  name: 'operatingSystems',
  initialState: {
    os: {},
    loading: false,
  },
  reducers: {
    operatingSystemsRequested: (operatingSystems) => {
      operatingSystems.loading = true;
    },
    operatingSystemsReceived: (operatingSystems, action) => {
      operatingSystems.os = action.payload;
      operatingSystems.loading = false;
    },
    operatingSystemsRequestFailed: (operatingSystems) => {
      operatingSystems.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function.
export const { operatingSystemsRequested, operatingSystemsReceived, operatingSystemsRequestFailed } = operatingSystemsSlice.actions;
export default operatingSystemsSlice.reducer;