import { createSlice } from "@reduxjs/toolkit";

export const legacyInstructionsSlice = createSlice({
  name: 'legacyInstructions',
  initialState: {
    instructions: {},
    loading: false,
  },
  reducers: {
    legacyInstructionsRequested: (legacy) => {
      legacy.loading = true;
    },
    legacyInstructionsReceived: (legacy, action) => {
      legacy.instructions = action.payload;
      legacy.loading = false;
    },
    legacyInstructionsRequestFailed: (legacy) => {
      legacy.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function.
export const { legacyInstructionsRequested, legacyInstructionsReceived, legacyInstructionsRequestFailed } = legacyInstructionsSlice.actions;
export default legacyInstructionsSlice.reducer;