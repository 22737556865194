import PagesPlaceholder from "./PagesPlaceholder";

interface Product {
  title: string;
  description: string;
  urlDocs: string;
  urlDocsArr?: {id: string, url: string}[];
  urlProductInfo: string;
  urlProductCompatibility: string;
  urlReleaseNotes: string;
  urlReleaseNotesArr?: {id: string, url: string}[];
  type: string;
}

interface Products {
  [key: string]: Product;
}

const ProductsPlaceholder: Products = {
  epas: {
    title: "EDB Postgres Advanced Server",
    description: "Enterprise-ready, Postgres with Oracle Compatibility and TDE",
    urlDocs: "/docs/epas/latest/",
    urlProductInfo: PagesPlaceholder.epas.url,
    urlProductCompatibility: "",
    urlReleaseNotes: "/docs/epas/latest/epas_rel_notes/",
    type: "epas"
  },
  epas_extended: {
    title: "EDB Postgres Extended Server",
    description: "PostgreSQL-compatible with TDE from EDB",
    urlDocs: "/docs/pge/latest/",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "/docs/pge/latest/release_notes/",
    type: "edb-postgres-extended"
  },
  pg: {
    title: "PostgreSQL",
    description: "PostgreSQL, supported by EDB",
    urlDocs: "/docs/supported-open-source/postgresql/",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "",
    type: "pg"
  },
  tpa: {
    title: "Trusted Postgres Architect",
    description: "Automated Postgres Deployments from EDB",
    urlDocs: "/docs/tpa/latest",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "/docs/tpa/latest/rel_notes/",
    type: "tpa"
  },
  bdr: {
    title: "EDB Postgres Distributed",
    description: "Up to Five 9s Extreme High Availability",
    urlDocs: "/docs/pgd/latest/quickstart/",
    urlProductInfo: "/products/edb-postgres-distributed",
    urlProductCompatibility: "",
    urlReleaseNotes: "/docs/pgd/latest/rel_notes/",
    type: "pgd"
  },
  barman: {
    title: "Barman",
    description: "Automate backup and recovery for PostgreSQL",
    urlDocs: "/docs/supported-open-source/barman/",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "",
    type: "barman"
  },
  bart: {
    title: "Backup and Recovery Tool",
    description: "Disaster recovery for PostgreSQL",
    urlDocs: "/docs/bart/latest/",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "",
    type: "bart"
  },
  pem: {
    title: "Postgres Enterprise Manager",
    description: "Manage, monitor, and optimize PostgreSQL",
    urlDocs: "/docs/pem/latest/",
    urlProductInfo: PagesPlaceholder.pem.url,
    urlProductCompatibility: "",
    urlReleaseNotes: "/docs/pem/latest/pem_rel_notes/",
    type: "pem"
  },
  efm: {
    title: "Failover Manager",
    description: "High availability for PostgreSQL",
    urlDocs: "/docs/efm/latest/",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "/docs/efm/latest/efm_rel_notes/",
    type: "efm"
  },
  patroni: {
    title: "Patroni",
    description: "Manage replication and failover for PostgreSQL",
    urlDocs: "/docs/supported-open-source/patroni/",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "",
    type: "patroni"
  },
  PgBouncer: {
    title: "PgBouncer",
    description: "Lightweight connection pooling utility for PostgreSQL",
    urlDocs: "/docs/pgbouncer/latest/",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "/docs/pgbouncer/latest/pgbouncer_rel_notes/",
    type: "PgBouncer"
  },
  pgPool: {
    title: "Pgpool-II",
    description: "Robust query routing and connection pooling for PostgreSQL",
    urlDocs: "/docs/pgpool/latest/",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "/docs/pgpool/latest/pgpool_rel_notes/",
    type: "pgPool"
  },
  eprs: {
    title: "Replication Server",
    description:
      "Synchronize data to or from non-PostgreSQL, as well as, between PostgreSQL databases",
    urlDocs: "/docs/eprs/latest/",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "/docs/eprs/latest/eprs_rel_notes/",
    type: "eprs"
  },
  migrationToolkit: {
    title: "Migration Toolkit",
    description: "Command-line data migration tool",
    urlDocs: "/docs/migration_toolkit/latest/",
    urlProductInfo: PagesPlaceholder.migrationToolkit.url,
    urlProductCompatibility: "",
    urlReleaseNotes: "/docs/migration_toolkit/latest/mtk_rel_notes/",
    type: "migrationToolkit"
  },
  /*harp: {
    title: "High Availability Routing for Postgres",
    description: "High availability for PostrgreSQL",
    urlDocs: "/docs/harp/latest/",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "/docs/harp/latest/11_release-notes/",
    type: "harp"
  },*/
  liveCompare: {
    title: "LiveCompare",
    description: "Data consistency validation and repair tool",
    urlDocs: "/docs/livecompare/latest/",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "/docs/livecompare/latest/appendix_a/",
    type: "liveCompare"
  },
  PostGIS: {
    title: "PostGIS",
    description: "Geospatial extension for EDB Postgres Advanced Server ",
    urlDocs: "/docs/postgis/latest/",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "",
    type: "PostGIS"
  },
  foreignDataWrappers: {
    title: "Foreign Data Wrappers",
    description:
      "Transparent data integrations for EDB Postgres Advanced Server",
    urlDocs: "",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "",
    type: "foreignDataWrappers"
  },
  connectors: {
    title: "EDB Connectors",
    description:
      "EDB Postgres Advanced Server connectors, including JDBC, ODBC, .NET, and OCL",
    urlDocs: "/docs/ocl_connector/latest/",
    urlDocsArr: [
      {id: "net", url: "/docs/net_connector/latest/"},
      {id: "jdbc", url: "/docs/jdbc_connector/latest/"},
      {id: "odbc", url: "/docs/odbc_connector/latest/"},
      {id: "ocl", url: "/docs/ocl_connector/latest/"}
    ],
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "/docs/ocl_connector/latest/ocl_rel_notes/",
    urlReleaseNotesArr: [
      {id: "net", url: "/docs/net_connector/latest/01_release_notes/"},
      {id: "jdbc", url: "/docs/jdbc_connector/latest/01_jdbc_rel_notes/"},
      {id: "odbc", url: "/docs/odbc_connector/latest/01_odbc_rel_notes/"},
      {id: "ocl", url: "/docs/ocl_connector/latest/ocl_rel_notes/"}
    ],
    type: "connectors"
  },
  jdbsDrivers: {
    title: "Third Party JDBC Drivers",
    description: "For Oracle, MySQL, SQL and Sybase, and PostgreSQL",
    urlDocs: "",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "",
    type: "jdbsDrivers"
  },
  toolkit: {
    title: "Modified GPL and Source Code Utilities",
    description: "Modified PostgreSQL components",
    urlDocs: "",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "",
    type: "toolkit"
  },
  pgk8s: {
    title: "EDB Postgres for Kubernetes",
    description: "for platform compatiblity page only",
    urlDocs: "/docs/postgres_for_kubernetes/latest/",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "",
    type: "for platform compatibility page only"
  },
  bdrk8s: {
    title: "EDB Postgres Distributed for Kubernetes",
    description: "for platform compatiblity page only",
    urlDocs: "/docs/postgres_distributed_for_kubernetes/latest/",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "",
    type: "for platform compatibility page only"
  },
  cnpg: {
    title: "CloudNativePG",
    description: "for platform compatiblity page only",
    urlDocs: "/docs/supported-open-source/cloud_native_pg/",
    urlProductInfo: "",
    urlProductCompatibility: "",
    urlReleaseNotes: "",
    type: "for platform compatibility page only"
  }
};

export default ProductsPlaceholder;
