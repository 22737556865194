import { createSlice } from "@reduxjs/toolkit";

//const redirectURL: string = "";

export const redirectURLSlice = createSlice({
  name: 'redirectURL',
  initialState: {
    url: ""
  },
  reducers: {
    setRedirectURL: (redirectURL, action) => {
     redirectURL.url = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function.
export const { 
  setRedirectURL
} = redirectURLSlice.actions;
export default redirectURLSlice.reducer;