import { useEffect, useRef, useState } from "react";
import { ReposAccess } from "../../resources/Types";
import { useSelector } from "react-redux";
import { cloudsmithTokenApi } from "../../client/edbApiClient";
import { Repos2NameMapping } from "./repos2NameMapping";

interface reposAccessState {
  reposAccess: ReposAccess;
}

const Repos2PackagesUrls = () => {
  const [token, setToken] = useState<string>('');
  const spinnerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const reposListRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const reposAccess = useSelector((state: reposAccessState) => state.reposAccess);
  const repos = 'access' in reposAccess && 'cloudsmith' in reposAccess.access ? reposAccess.access.cloudsmith : {};
  
  useEffect(() => {
    cloudsmithTokenApi()
    .then((res) => {
      if (typeof res === "object" && Object.keys(res).length > 0 && "token" in res && res['token'] !== "") {
        setToken(
          typeof res['token'] === "string" ? res['token'] : ""
        );
      }
      return res;
    })
  }, [])

  useEffect(() => {
    if (typeof repos === "object" && Object.keys(repos).length > 0 && token !== "") {
      spinnerRef.current.className = spinnerRef.current.className + ' d-none'
      reposListRef.current.className = 'block';
    }
  }, [repos, token])


  return (
    <div className=" pt-2 px-3-mobile container mobile-container">
      <h2 id="repository-packages">Download EDB software packages from your browser</h2>
      <p>
        Select a repository to browse and download packages.
      </p>
      <div className="card shadow py-3 ps-5 mb-5 bg-body rounded">
        <div className="text-center">
          <div className="spinner-border spinner-border text-secondary mx-2" ref={spinnerRef} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
        <div ref={reposListRef} className="d-none">
          {Object.keys(repos).filter((repo) => {
            return repo !== "status" && !repo.includes("k8s");
          }).map((repo) => {
            // Only production ones exist.
            const repoProd = repo.replace("staging_", "");
            const url = `https://downloads.enterprisedb.com/${token}/${repoProd}/raw/files/${repoProd}.html`;
            return (
              <div key={repo} className="py-1">
                <a href={url} target="_blank" className="link-teal text-decoration-none">
                  {Repos2NameMapping[repoProd]}
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Repos2PackagesUrls