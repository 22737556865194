'use client'
import Logo from "./Logo";
import HeaderMenu from "./HeaderMenu";
import SearchButton from "./SearchButton";
import SearchInput from "./SearchInput";
import SignIn from "./SignIn";
import Hamburger from "./hamburger";
import { useRef, useState } from "react";
import styles from './styles.module.scss';

const HeaderStructure = () => {
  const headerMenuWrapperRef = useRef<HTMLDivElement>(null);
  const headerSearchBoxWrapperRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const sendDataToParent = (inputBoxClass: string, topMenuClass: string) => {
    if (headerMenuWrapperRef.current) {
      if (headerMenuWrapperRef.current.className === styles.headerMenuWrapperOpened) {
        headerMenuWrapperRef.current.className = styles.headerMenuWrapperOpened;
      }
      else {
        headerMenuWrapperRef.current.className = topMenuClass === "hidden" ? styles.headerMenuWrapperHidden : styles.headerMenuWrapper;
      }
    }
    if (headerSearchBoxWrapperRef.current) {
      headerSearchBoxWrapperRef.current.className = inputBoxClass === "inline" ? styles.headerSearchBoxWrapperOpened : styles.headerSearchBoxWrapper;
    }
  }

  return (
    <>
      <div className={styles.headerStructure}>
        <div className={styles.headerContentWrapper}>
          <Hamburger menuStatus={menuOpen} ref={headerMenuWrapperRef} />
          <Logo />
          <div className={styles.headerMenuWrapper} ref={headerMenuWrapperRef}>
            <HeaderMenu />
          </div>
          <div className={styles.headerSearchBoxWrapper} ref={headerSearchBoxWrapperRef}>
            <SearchInput />
          </div>
          <SignIn />
          <SearchButton sendDataToParent={sendDataToParent} />
        </div>
        
      </div>
      <div></div>
    </>
  )
}

export default HeaderStructure