import React from "react";
import { cloudsmithTokenApi, marketoPostData } from "../client/edbApiClient";
import { Cloudsmith } from "../resources/Types";
import { getCurrentDate } from "../utils"
import { email } from "../resources/OktaSessionData";

interface ButtonProps {
  label: string;
  href: string;
  btnClass?: string;
  color?: string;
  type?: string;
  requestAccess?: boolean;
  spinner?: boolean;
}

const ButtonEDB = React.forwardRef(( props: ButtonProps, ref: any ) => {
  let colorClass = ' orange';
  let typeClass = ' primary';
  let btnClass = ''
  if (props.color) {
    colorClass = props.color === 'orange' || props.color === 'white' || props.color === 'teal' ? ` ${props.color}` : ' orange';
  }
  if (props.type) {
    typeClass = props.type === 'primary' || props.type === 'secondary' || props.type === 'link' ?  ` ${props.type}` : ' primary';
  }
  if (props.btnClass) {
    btnClass = ` ${props.btnClass}`
  }

  const spinner = props.spinner !== undefined ? props.spinner : false;

  const requestAccessClick = async (e: React.MouseEvent<HTMLElement>) => {
    if (props.requestAccess) {
      // Stop link going to repos 2.0 page.
      e.preventDefault();
      // Show waiting spinner.
      ref.current.className = "spinner-border spinner-border-sm text-secondary";

      await marketoPostData({
        "download_access_start_date": getCurrentDate(),
        "has_individual_token": true,
        "email": email
      });

      // Create token.
      return await cloudsmithTokenApi()
      .then((res: Cloudsmith[] | unknown) => {
        window.location.reload();
      })
    }
  }
  
  if (ref !== null && 'current' in ref && ref.current !== "undefined") {
    return (
      <>
        <a 
          href={props.href} 
          className={`edb-btn${colorClass}${typeClass}${btnClass}`}
          ref={ref}
          onClick={(e) => requestAccessClick(e)}
        >
          {props.label}
        </a>
        {spinner && (
          <div className="spinner-border spinner-border-sm text-secondary d-none" role="status" ref={ref}>
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
      </>
    )
  }
  else {
    return (
      <a 
        href={props.href} 
        className={`edb-btn${colorClass}${typeClass}${btnClass}`}
        onClick={(e) => requestAccessClick(e)}
      >
        {props.label}
      </a>
    )
  }
})

export default ButtonEDB;