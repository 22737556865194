import React from "react";
import { 
  CloudSmithYumInstallationContent,
  CloudSmithAptInstallationContent,
  CloudSmithUbunutInstallationContent,
  CloudSmithSlesInstallationContent,
  CloudSmithDnfInstallationContent 
} from "./CloudsmithLinuxInstallationContent";
import { ReplacementData } from "../../resources/Types";
import InstructionsCloudsmith from "./InstructionsCloudsmith";
import { useSelector, useDispatch } from "react-redux";
import { instructionsForLegacyApi } from "../../client/edbApiClient";
import { legacyInstructionsReceived } from "../../store/legacyInstructionsSlice";
import InstructionsLegacy from "./InstructionsLegacy";
import { LegacyInstructions } from "../../resources/Types";

interface InstructionsTemplateProps {
  codeName: string | null | undefined;
  distro: string | null | undefined;
  fingerprint: string | null | undefined;
  packageName: string | null | undefined;
  repo: string | null | undefined;
  token: string | null | undefined;
  template: string;
  repoType: string;
  packages: [];
}

interface InstructionCommands {
  installationTextAutoCommand?: string;
  installationTextInstallCommand?: string;
  installationTextManualCommand?: string;
}

interface legacyIdsState {
  legacyIds: {
    os: string;
    version: number;
  };
}

const Instructions = ( props: InstructionsTemplateProps ) => {
  let data: ReplacementData;
  let instructions: InstructionCommands = {};
  let instructionsAuto: string | undefined = "";
  let instructionsManual: string | undefined = "";
  let instructionsInstall: string | undefined  = "";
  const legacyIds = useSelector((state: legacyIdsState) => state.legacyIds);
  const dispatch = useDispatch();

  // Make sure no duplicate package names when clicking on a different version for same product.
  const uniquePackages = props.packages.filter((v, i, a) => a.indexOf(v) === i);

  const fetchLegacyIdsForInstructions = async (versionId: number, osId: string) => {
    if (typeof versionId === "number" && typeof osId === "string") {
      return await instructionsForLegacyApi(versionId, osId)
      .then((res: LegacyInstructions | unknown) => {
        // Save to the redux store.
        dispatch(legacyInstructionsReceived(res));
      })
    }
  }

  switch(props.repoType) {
    case 'cloudsmith':
      data = {
        "token": props.token,
        "repo": props.repo,
        "fingerprint_short": props.fingerprint,
        "distro": props.distro,
        "code_name": props.codeName,
        "package_names": uniquePackages.join(" "),
      }

      switch(props.template) {
        case 'yum':
          instructions = CloudSmithYumInstallationContent(data);
          break;
        case 'apt':
          instructions = CloudSmithAptInstallationContent(data);
          break;
        case 'sles':
          instructions = CloudSmithSlesInstallationContent(data);
          break;
        case 'dnf':
          instructions = CloudSmithDnfInstallationContent(data);
          break;
      }

      instructionsAuto = instructions.installationTextAutoCommand;
      instructionsManual = instructions.installationTextManualCommand;
      instructionsInstall = instructions.installationTextInstallCommand;
      break;
    case 'legacy':
      if (typeof legacyIds === "object" && Object.keys(legacyIds).length > 0 &&
      'os' in legacyIds && legacyIds.os !== "" && 
      'version' in legacyIds && typeof legacyIds.version === "number") {
        const versionId = legacyIds.version;
        const osId = legacyIds.os;
        fetchLegacyIdsForInstructions(versionId, osId)
      }
      break;
    default:

  }

  if (Object.keys(instructions).length) {
    return (
      <InstructionsCloudsmith 
        auto={typeof instructionsAuto !== "undefined" ? instructionsAuto : ""} 
        manual={typeof instructionsManual !== "undefined" ? instructionsManual : ""}
        install={typeof instructionsInstall !== "undefined" ? instructionsInstall : ""}
      />
    )
  }
  else {
    return (
      <InstructionsLegacy />
    )
  }
  

}

export default Instructions;