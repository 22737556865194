import { useRef } from "react";
import ProductDetails from "./ProductDetails";
import { dwProductApi, dwProductMetaApi } from "../../client/edbApiClient";
import { useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { productsReceived } from "../../store/productSlice";
import { productsMetaReceived } from "../../store/productsMetaSlice";
import AuthModal from "../AuthModal";
import { ProductsMetaProduct } from "../../resources/Types";

interface Product {
  list: {
    title: string;
    field_installer_version: string;
    field_sub_version: string;
    field_os: string;
    field_installation_method: string;
    field_supported_os: string;
    uuid: string;
    field_show_this_version: string;
    field_campaign_id: string;
    field_product_category: string;
    field_asset_details: string;
  }
}

interface productState {
  products: Product;
}

interface authModalState {
  authModal: {
    show: boolean
  };
}

const Products = () => {
  const allProducts = useSelector((state: productState) => state.products.list);
  const showAuthModal = useSelector((state: authModalState) => state.authModal.show)
  const productsMeta = useSelector((state: any) => state.productsMeta);
  const dispatch = useDispatch();

  const productRef = useRef(new Array(Object.keys(productsMeta.list).length));

  useLayoutEffect(() => {
    // Get Products.
    fetchProducts()

    fetchProductsMeta();

    // Scroll Down if anchor tag in url.
    // Need to wait a couple of seconds to allow products from api and build page
    // so that anchor is available to go to.
    setTimeout(() => {
      if (window.location.hash) {
        productRef.current.map((v: HTMLLIElement) => {
          if (window.location.hash === `#${v.id}`) {
            v.scrollIntoView();
            v.focus();
          }
        });
      }
    }, 2000)
  }, [dispatch])

  const fetchProducts = async () => {
    return await dwProductApi()
    .then(res => {
      if (typeof res !== "undefined") {
      // Save to the redux store
      dispatch(productsReceived(res))
      }
      else {
        dispatch(productsReceived([]))
      }
    })
  }

  const fetchProductsMeta = async () => {
    return await dwProductMetaApi()
    .then(res => {
      if (typeof res !== "undefined") {
        // Save to the redux store
        dispatch(productsMetaReceived(res))
      }
      else {
        dispatch(productsMetaReceived([]))
      }
    })
  }

  // Make copy of allProducts to prevent cannot assign read only propert error.
  const allProductsCopy = JSON.parse(JSON.stringify(allProducts));
  

  let products: ProductsMetaProduct | unknown;
  if (typeof productsMeta === 'object' && 'list' in productsMeta && Object.keys(productsMeta.list).length > 0) {
    products = productsMeta.list
    const ProductsCopy = JSON.parse(JSON.stringify(products));
  
    return (
      <>
        {}
        <ul className="list-unstyled" id="product-list">
          {Object.entries(ProductsCopy).map((product, index) => {
            const productDetails: any = product[1];
            let versions = [];

            if (Array.isArray(allProductsCopy) && allProductsCopy.length) {
              versions = allProductsCopy.filter(
                category => category.field_product_category === productDetails.title || 
                category.field_product_category === productDetails.products_foreign_title
                )
            }

            return (
              <ProductDetails 
                key={productDetails.title}
                product={productDetails}
                versions={versions}
                ref={(element) => typeof index === "number" ? productRef.current[index] = element : ''}
              />
            )
            
          })}
        </ul>
        <AuthModal show={showAuthModal} />
      </>
    )
  }
  else {
    return <></>
  }
}

export default Products;