import { useRef } from "react";
import { Link } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { obsidian } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import WhichSubscriptionsPlanModal from "./WhichSubscriptionPlanModal";
import ToolTipOverlay from "../ToolTipOverlay";

interface InstructionsCloudsmithProps {
  auto: string;
  manual: string;
  install: string;
}

const InstructionsCloudsmith = ( props: InstructionsCloudsmithProps ) => {
  const copyAutoRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const copiedAutoRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const copyManualRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const copiedManualRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const copyStepTwoRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const copiedStepTwoRef = useRef() as React.MutableRefObject<HTMLSpanElement>;

  return (
    <div className="repos-instructions">
      <div className="fs-4 pb-2">
        Step 1
      </div>
        <div>
          We offer two ways to set up your repository. Once configured, additional packages can be installed by going directly to Step 2.
        </div>
        <WhichSubscriptionsPlanModal />
      <div className="instructions-subheader">
        Auto
      </div>
      <div className="d-flex justify-content-between">
        <div>
          Use this to quickly setup the repository automatically (recommended).
        </div>
        <div>
          <Link
            to={''}
            className={'text-decoration-none copy-script me-3'}
            onClick={(event) => {
              event.preventDefault();
              navigator.clipboard.writeText(
                props.auto
              )
              copyAutoRef.current.className = "d-none";
              copiedAutoRef.current.className = "show";
              setTimeout(() => {
                copyAutoRef.current.className = "show";
                copiedAutoRef.current.className = "d-none"
              }, 3000)
            }}
          >
            <span 
              ref={copyAutoRef}
            >
              <ToolTipOverlay
                keyId="instructions-copy"
                placement="top"
                tooltipLabel="Copy Instructions"
                tooltipClass=""
                component="CopyIcon"
              />
            </span>
            <span 
              ref={copiedAutoRef}
              className="d-none"
            >
              <ToolTipOverlay
                keyId="instructions-copied"
                placement="top"
                tooltipLabel="Copied Instructions"
                tooltipClass=""
                component="CopiedIcon"
              />
            </span>
          </Link>
        </div>
      </div>
      <SyntaxHighlighter language="bash" style={obsidian}>
        {props.auto}
      </SyntaxHighlighter>
      <div className="mt-2 instructions-subheader">
        Manual
      </div>
      <div className="d-flex justify-content-between">
        <div>
          Configure the repository yourself before installing packages.
        </div>
        <div>
        <Link
            to={''}
            className={'text-decoration-none copy-script me-3'}
            onClick={(event) => {
              event.preventDefault();
              navigator.clipboard.writeText(
                props.manual
              )
              copyManualRef.current.className = "d-none";
              copiedManualRef.current.className = "show";
              setTimeout(() => {
                copyManualRef.current.className = "show";
                copiedManualRef.current.className = "d-none";
              }, 3000)
            }}
          >
            <span 
              ref={copyManualRef}
            >
              <ToolTipOverlay
                keyId="instructions-copy"
                placement="top"
                tooltipLabel="Copy Instructions"
                tooltipClass=""
                component="CopyIcon"
              />
            </span>
            <span 
              ref={copiedManualRef}
              className="d-none"
            >
              <ToolTipOverlay
                keyId="instructions-copied"
                placement="top"
                tooltipLabel="Copied Instructions"
                tooltipClass=""
                component="CopiedIcon"
              />
            </span>
          </Link>
        </div>
      </div>
      <SyntaxHighlighter language="bash" style={obsidian}>
        {props.manual}
      </SyntaxHighlighter>
      <div className="fs-4 mt-5 pb-2">
        Step 2
      </div>
      <div className="d-flex justify-content-between">
        <div>
          Once the repository is configured, run this to install the software packages.
        </div>
        <div>
          <Link
            to={''}
            className={'text-decoration-none copy-script me-3'}
            onClick={(event) => {
              event.preventDefault();
              navigator.clipboard.writeText(
                props.install
              )
              copyStepTwoRef.current.className = "d-none";
              copiedStepTwoRef.current.className = "show";
              setTimeout(() => {
                copyStepTwoRef.current.className = "show";
                copiedStepTwoRef.current.className = "d-none";
              }, 3000)
            }}
          >
                        <span 
              ref={copyStepTwoRef}
            >
              <ToolTipOverlay
                keyId="instructions-copy"
                placement="top"
                tooltipLabel="Copy Instructions"
                tooltipClass=""
                component="CopyIcon"
              />
            </span>
            <span 
              ref={copiedStepTwoRef}
              className="d-none"
            >
              <ToolTipOverlay
                keyId="instructions-copied"
                placement="top"
                tooltipLabel="Copied Instructions"
                tooltipClass=""
                component="CopiedIcon"
              />
            </span>
          </Link>
        </div>
      </div>
      <SyntaxHighlighter language="bash" style={obsidian}>
        {props.install}
      </SyntaxHighlighter>
    </div>
  )
}

export default InstructionsCloudsmith;