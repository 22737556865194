import React, { useRef, useState } from 'react';
import styles from './styles.module.scss';
import Close from "../../icons/close";

interface Props {
  menuStatus: boolean;
}

// eslint-disable-next-line react/display-name
const hamburger = React.forwardRef((_props: Props, ref: any) => {
  const [menuOpen, setMenuOpen] = useState(false) 
  const hamburgerOpenRef = useRef<null | HTMLSpanElement>(null)
  const hamburgerCloseRef = useRef<null | HTMLSpanElement>(null)

  const handleOnClick = () => {
    if (menuOpen === false) {
      setMenuOpen(true);
      ref.current.className = `${styles.headerMenuWrapperOpened}`;
      // Too much to pass refs around here so get by id in SignIn component.
      document.getElementById('loginLinkItemsId')?.classList.add(styles.loginLinkItemsOpened);
      if ("current" in hamburgerOpenRef && hamburgerOpenRef.current !== null && "className" in hamburgerOpenRef.current) {
        hamburgerOpenRef.current.className = `${styles.hamburgerClosed}`;
      }
      if ("current" in hamburgerCloseRef && hamburgerCloseRef.current !== null && "className" in hamburgerCloseRef.current) {
        hamburgerCloseRef.current.className = `${styles.hamburgerCloseOpened}`;
      }
    }
    else {
      setMenuOpen(false);
      ref.current.className = styles.headerMenuWrapper;
      document.getElementById('loginLinkItemsId')?.classList.remove(styles.loginLinkItemsOpened);
      document.getElementById('loginLinkItemsId')?.classList.add(styles.loginLinkItems);
      if ("current" in hamburgerOpenRef && hamburgerOpenRef.current !== null && "className" in hamburgerOpenRef.current) {
        hamburgerOpenRef.current.className = styles.hamburger;
      }
      if ("current" in hamburgerCloseRef && hamburgerCloseRef.current !== null && "className" in hamburgerCloseRef.current) {
        hamburgerCloseRef.current.className = styles.hamburgerClose;
      }
    }
  }

  return (
    <>
      <span 
        className={styles.hamburger} 
        onClick={handleOnClick}
        ref={hamburgerOpenRef}
      >
        |||
      </span>
      <span 
        className={styles.hamburgerClose} 
        onClick={handleOnClick}
        ref={hamburgerCloseRef}
      >
        <Close />
      </span>
    </>
  )
})

export default hamburger