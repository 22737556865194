import React, { useRef } from 'react';
import { SearchIcon } from '../../icons/search';
import styles from './styles.module.scss';

const SearchInput = () => {
  const inputBoxRef = useRef<HTMLInputElement>(null);

  const handleInputClick = (e: any) => {
    if (inputBoxRef.current) {
      const inputVal = inputBoxRef.current.value;
      window.location.replace(`https://www.enterprisedb.com/search?q=${inputVal}`);
    }
  }

  const handleSubmitKeyDown = (e: any) => {
    if (e.code === "Enter") {
      if (inputBoxRef.current) {
        const inputVal = inputBoxRef.current.value;
        window.location.replace(`https://www.enterprisedb.com/search?q=${inputVal}`);
      }
    }
  }

  return (
    <>
      <div>
        <button 
          type='submit' 
          className={styles.searchInputSubmitBtn}
          onClick={handleInputClick}
        >
          <SearchIcon />
        </button>
      </div>
      <input 
        className="search-input placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-zinc-400 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1" 
        placeholder="Search products, resources & more" 
        type="text" name="search" 
        ref={inputBoxRef}
        onKeyDown={handleSubmitKeyDown}
      />
    </>
  )
}

export default SearchInput