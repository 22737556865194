import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { OperatingSystems, ProductsByOs, InstructionsReplacements } from "../../resources/Types";
import Instructions from "./Instructions";

interface operatingSystemsState {
  operatingSystems: OperatingSystems;
}

interface productsByOsState {
  productsByOs: ProductsByOs;
}

interface instructionsReplacementState {
  instructionsReplacement: InstructionsReplacements;
}

interface ReposInstructionsProps {
  repoType: string;
}

const ReposInstructions = ( props: ReposInstructionsProps ) => {
  const allOs = useSelector((state: operatingSystemsState) => state.operatingSystems);
  const productsByOs = useSelector((state: productsByOsState) => state.productsByOs);
  const instructionsReplacements = useSelector((state: instructionsReplacementState) => state.instructionsReplacement);
  const wrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const spinnerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const textRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const instructionsRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  // Check if we have operating systems from the Redux store.
  if (typeof allOs.os.data !== "undefined" && allOs.os.data.length) {
    // Remove centered text.
    wrapperRef.current.className = 'instructions';
    // Hide spinner.
    spinnerRef.current.className = spinnerRef.current.className + ' d-none';
    // Show text in it's place.
    textRef.current.className = 'show';
  }

  // Show spinner for select software if we have allOs but loading for productsByOs.
  if (typeof allOs.os.data !== "undefined" && allOs.os.data.length && 
  'loading' in productsByOs && productsByOs.loading === true) {
    // Center the wrapper.
    wrapperRef.current.className = 'instructions text-center';
    // Show Spinner.
    spinnerRef.current.className = 'spinner-border';
    // Show text in it's place.
    textRef.current.className = 'd-none';
  }

  // Show spinner for select software checkbox for instructions 
  // if we have productsByOs but loading for instructionsReplacements.
  if (typeof productsByOs === "object" 
  && Object.keys(productsByOs).length > 0 && 
  'loading' in instructionsReplacements && instructionsReplacements.loading === true) {
    // Center the wrapper.
    wrapperRef.current.className = 'instructions text-center';
    // Show Spinner.
    spinnerRef.current.className = 'spinner-border';
    // Hide text.
    textRef.current.className = 'd-none';
  }

  // Get template to pass in to instructions.
  let template = '';
  if (typeof allOs.os.data !== "undefined" && 
  allOs.os.data.length && 'loading' in productsByOs && productsByOs.loading === false &&
  typeof productsByOs === "object" && 
  'productsByOs' in productsByOs && 
  typeof productsByOs.productsByOs == "object" &&
  Object.keys(productsByOs.productsByOs).length > 0 && 'productsByOs' in productsByOs) {
    if ('data' in productsByOs.productsByOs) {
      if ('products' in productsByOs.productsByOs.data) {
        const osProducts = productsByOs.productsByOs.data.products;
        if (Array.isArray(osProducts) && osProducts.length > 0) {
          if (typeof osProducts[0] === "object" && 'template' in osProducts[0] && typeof osProducts[0].template === "string") {
            template = osProducts[0].template
          }
        }
      }
    }
  }

  let codeName: string | null | undefined = '';
  let distro: string | null | undefined = '';
  let fingerprint: string | null | undefined = '';
  let packageName: string | null | undefined = '';
  let repo: string | null | undefined = '';
  let token: string | null | undefined = '';
  let packages: [] = [];

  // Show instructions if replacements are available.
  if (typeof instructionsReplacements === "object" 
  && 'instructions' in instructionsReplacements && 
  Object.keys(instructionsReplacements.instructions).length) {
    codeName = instructionsReplacements.instructions.code_name;
    distro = instructionsReplacements.instructions.distro;
    fingerprint = instructionsReplacements.instructions.fingerprint_short;
    packageName = instructionsReplacements.instructions.package_name;
    repo = instructionsReplacements.instructions.repo;
    token = instructionsReplacements.instructions.token;
    packages = Array.isArray(instructionsReplacements.packages) && instructionsReplacements.packages.length ? instructionsReplacements.packages : [];

    // Hide text.
    textRef.current.className = 'd-none';
    // Show Instructions.
    instructionsRef.current.className = 'show';
  }

  // Hide repo instructions if nothing to show.
  if (('instructions' in instructionsReplacements && Object.keys(instructionsReplacements.instructions).length === 0) || 
  'packages' in instructionsReplacements && Array.isArray(instructionsReplacements.packages) && instructionsReplacements.packages.length === 0 || 
  template === "") {
    if ('current' in instructionsRef && typeof instructionsRef.current !== "undefined" && 'className' in instructionsRef.current) {
      instructionsRef.current.className = 'd-none';
      textRef.current.className = 'show';
    }
  }

  // If spinner is showing indicating loading make sure instructions and text is hidden.
  if ('current' in spinnerRef && typeof spinnerRef.current !== "undefined" && spinnerRef.current.className === 'spinner-border') {
    instructionsRef.current.className = 'd-none';
    textRef.current.className = 'd-none';
  }

  const anchorLink = props.repoType === "legacy" ? "#direct-repos-downloads" : "#repository-packages";

  return (
    <div className="instructions text-center" ref={wrapperRef}>
      <div className="spinner-border" role="status" ref={spinnerRef}>
        <span className="visually-hidden">Loading...</span>
      </div>
      <div className="d-none" ref={textRef}>
        Looking to <a 
          href={anchorLink}
          onClick={(e) => {
            e.preventDefault();
            document.getElementById(anchorLink.replace('#', ''))?.scrollIntoView();
          }}
          >{props.repoType === "legacy" ? 'Direct Repo Downloads' : 'download packages from your browser'}</a>?
      </div>
      <div className="d-none" ref={instructionsRef}>
        <Instructions
          codeName={codeName}
          distro={distro}
          fingerprint={fingerprint}
          packageName={packageName}
          repo={repo}
          token={token}
          template={template}
          repoType={props.repoType}
          packages={packages}
        />
      </div>
    </div>
  )
}

export default ReposInstructions;