import ButtonEDB from "./ButtonEdb";
import { useSelector, useDispatch } from "react-redux";
import { redirectURLState } from "../resources/Types";
import Modal from "react-bootstrap/Modal";
import { authModalReceived } from "../store/authModalSlice";
import { loginLink, registerLink, redirectParam } from "../resources/StaticVars";

interface AuthModalProps {
  show: boolean;
}

const AuthModal = ( props: AuthModalProps ) => {
  const redirectURL = useSelector((state: redirectURLState) => state.redirectURL);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(authModalReceived(false));
  }

  return (
    <>
      <Modal 
        show={props.show} 
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-1">
          <div className="px-6 pb-6 fw-bold">
            Sign in or create EDB account
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
          <p className="text-center">
            You'll need an EDB account to access our software repositories and downloads:
          </p>
          <div className="d-flex justify-content-center">
            <div>
              <ButtonEDB label="Sign In" color="teal" href={`${loginLink}?${redirectParam}=${encodeURIComponent(redirectURL.url)}`} btnClass="m-3" />
              <ButtonEDB label="Create New Account" color="teal" href={`${registerLink}?${redirectParam}=${encodeURIComponent(redirectURL.url)}`} />
            </div>
          </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AuthModal;