import { useEffect } from "react";

const TrustArc = () => {
  const trustArcUrl = "//consent.trustarc.com/notice?domain=enterprisedb.com&c=teconsent&js=nj&noticeType=bb&text=true&gtm=1";
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.async = true;
    script.crossOrigin = "anonymous";
    script.defer = true;

    script.setAttribute("src", trustArcUrl);
    head?.append(script);
  }, [trustArcUrl])

  return (
    <>
      <div className="position-fixed bottom-0 w-100" style={{ zIndex: 99 }}>
        <div id="consent_blackbar" />
      </div>
    </>
  )
}

export default TrustArc;