// Template file for installation instructions for different packages.

import { ReplacementData } from "../../resources/Types";

// YUM.
const CloudSmithYumInstallationContent = ( data: ReplacementData ) => {
  const installationTextAutoCommand = `curl -1sLf 'https://downloads.enterprisedb.com/${data.token}/${data.repo}/setup.rpm.sh' | sudo -E bash`;
  let includePygpgme = '';

  // Only include pygpgme in install if lower than version 8. 
  if (typeof data.code_name === "string" && data.code_name !== "") {
    const codeNameVersion = Number(data.code_name);
    if (codeNameVersion < 8) {
      includePygpgme = 'pygpgme';
    }
  }
  
  const installationTextManualCommand = `yum install yum-utils ${includePygpgme}
rpm --import 'https://downloads.enterprisedb.com/${data.token}/${data.repo}/gpg.${data.fingerprint_short}.key'
curl -1sLf 'https://downloads.enterprisedb.com/${data.token}/${data.repo}/config.rpm.txt?distro=${data.distro}&codename=${data.code_name}' > /tmp/enterprisedb-${data.repo}.repo
yum-config-manager --add-repo '/tmp/enterprisedb-${data.repo}.repo'
yum -q makecache -y --disablerepo='*' --enablerepo='enterprisedb-${data.repo}'`;

  const installationTextInstallCommand = `yum -y install ${data.package_names}`;

  return {
    installationTextAutoCommand,
    installationTextManualCommand,
    installationTextInstallCommand,
  };
}

// APT.
const CloudSmithAptInstallationContent = ( data: ReplacementData ) => {
  const installationTextAutoCommand = `curl -1sLf 'https://downloads.enterprisedb.com/${data.token}/${data.repo}/setup.deb.sh' | sudo -E bash`;

  const installationTextManualCommand = `apt-get install -y debian-keyring  # debian only
apt-get install -y debian-archive-keyring  # debian only
apt-get install -y apt-transport-https
# For Debian Stretch, Ubuntu 16.04 and later
keyring_location=/usr/share/keyrings/enterprisedb-${data.repo}-archive-keyring.gpg
curl -1sLf 'https://downloads.enterprisedb.com/${data.token}/${data.repo}/gpg.${data.fingerprint_short}.key' |  gpg --dearmor > \${keyring_location}
curl -1sLf 'https://downloads.enterprisedb.com/${data.token}/${data.repo}/config.deb.txt?distro=${data.distro}&codename=${data.code_name}' > /etc/apt/sources.list.d/enterprisedb-${data.repo}.list
apt-get update`;

  const installationTextInstallCommand = `sudo apt-get -y install ${data.package_names}`;

  return {
    installationTextAutoCommand,
    installationTextManualCommand,
    installationTextInstallCommand,
  };
}

// Ubuntu.
const CloudSmithUbunutInstallationContent = ( data: ReplacementData ) => {
  const installationTextAutoCommand = `curl -1sLf 'https://downloads.enterprisedb.com/${data.token}/${data.repo}/setup.deb.sh' | sudo -E bash`;

  const installationTextManualCommand = `apt-get install -y apt-transport-https
# For Debian Stretch, Ubuntu 16.04 and later
keyring_location=/usr/share/keyrings/enterprisedb-${data.repo}-archive-keyring.gpg
curl -1sLf 'https://downloads.enterprisedb.com/${data.token}/${data.repo}/gpg.${data.fingerprint_short}.key' |  gpg --dearmor > \${keyring_location}
curl -1sLf 'https://downloads.enterprisedb.com/${data.token}/${data.repo}/config.deb.txt?distro=${data.distro}&codename=${data.code_name}' > /etc/apt/sources.list.d/enterprisedb-${data.repo}.list
apt-get update`;

  const installationTextInstallCommand = `sudo apt-get -y install ${data.package_names}`;

  return {
    installationTextAutoCommand,
    installationTextManualCommand,
    installationTextInstallCommand,
  };
}

// SLES.
const CloudSmithSlesInstallationContent = ( data: ReplacementData ) => {
  const installationTextAutoCommand = `curl -1sLf 'https://downloads.enterprisedb.com/${data.token}/${data.repo}/setup.rpm.sh' | sudo -E bash`;

  const installationTextManualCommand = `curl -1sLf 'https://downloads.enterprisedb.com/${data.token}/${data.repo}/config.rpm.txt?distro=${data.distro}&codename=${data.code_name}' > /tmp/enterprisedb-${data.repo}.repo
zypper ar -f '/tmp/enterprisedb-${data.repo}.repo'
zypper --gpg-auto-import-keys refresh enterprisedb-${data.repo} enterprisedb-${data.repo}-source`;

  const installationTextInstallCommand = `zypper -n install ${data.package_names}`;

  return {
    installationTextAutoCommand,
    installationTextManualCommand,
    installationTextInstallCommand,
  };
}

// DNF.
const CloudSmithDnfInstallationContent = ( data: ReplacementData ) => {
  const installationTextAutoCommand = `curl -1sLf 'https://downloads.enterprisedb.com/${data.token}/${data.repo}/setup.rpm.sh' | sudo -E bash`;
  let includePygpgme = '';

  // Only include pygpgme in install if lower than version 8.
  if (typeof data.code_name === "string" && data.code_name !== "") {
    const codeNameVersion = Number(data.code_name);
    if (codeNameVersion < 8) {
      includePygpgme = 'pygpgme';
    }
  }

  const installationTextManualCommand = `dnf install yum-utils ${includePygpgme}
rpm --import 'https://downloads.enterprisedb.com/${data.token}/${data.repo}/gpg.${data.fingerprint_short}.key'
curl -1sLf 'https://downloads.enterprisedb.com/${data.token}/${data.repo}/config.rpm.txt?distro=${data.distro}&codename=${data.code_name}' > /tmp/${data.repo}.repo
dnf config-manager --add-repo '/tmp/${data.repo}.repo'
dnf -q makecache -y --disablerepo='*' --enablerepo='enterprisedb-${data.repo}'`;

  const installationTextInstallCommand = `sudo dnf -y install ${data.package_names}`;

  return {
    installationTextAutoCommand,
    installationTextManualCommand,
    installationTextInstallCommand,
  };
}

export { 
  CloudSmithYumInstallationContent, 
  CloudSmithAptInstallationContent, 
  CloudSmithUbunutInstallationContent,
  CloudSmithSlesInstallationContent,
  CloudSmithDnfInstallationContent
}
