import { useEffect, useLayoutEffect, useRef } from "react";
import Layout from "../components/layout/Layout";
import ButtonEDB from "../components/ButtonEdb";
import { downloadInstallerApi } from "../client/edbApiClient";
import { useSelector, useDispatch } from "react-redux";
import { downloadInstallerReceived } from "../store/downloadInstallerSlice";
import HelpfulResources from "../components/HelpfulResources";
import { marketoPostData } from "../client/edbApiClient";
import { email } from "../resources/OktaSessionData";

interface downloadInstallerState {
  downloadInstaller: {
    installer: string;
  }
}

const ThankYouPage = () => {
  const installer = useSelector((state: downloadInstallerState) => state.downloadInstaller.installer);
  const dispatch = useDispatch();
  const manualBtnRef = useRef() as React.MutableRefObject<HTMLLinkElement>

  useLayoutEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const uuid = queryParams.get("uuid");
    const campaignId = queryParams.get("campaignId");
    if (uuid !== null) {
      fetchDownloadInstaller(uuid, campaignId)
    }
  }, [1])

  const fetchDownloadInstaller = async (uuid: string, campaignId: string | null) => {
    if (campaignId === null) {
      campaignId = "";
    }

    // Pass campaign id to marketo.
    if (campaignId !== "") {
      await marketoPostData({
        "campaign_id": campaignId,
        "email": email
      });
    }

    return await downloadInstallerApi(uuid, campaignId)
    .then(res => {
      dispatch(downloadInstallerReceived(res));
    })
  }

  // Automated download after url is available via api.
  useEffect(() => {
    installer && manualBtnRef.current && manualBtnRef.current.click();
  }, [installer, manualBtnRef]);

  return (
    <Layout>
      <div className="pt-5 px-3 bg-light">
        <h1 className="display-6">
          Thank you for downloading EDB Postgres
        </h1>
        <p className="lead py-2 fw-normal">
          Your download will begin momentarily.
        </p>
        <ButtonEDB 
          href={installer} 
          label={installer !== "" ? "Click here if your download does not start automatically." : "Loading..."}
          ref={manualBtnRef}
          color='teal'
        />
        <HelpfulResources />
      </div>
    </Layout>
  )
}

export default ThankYouPage;