import axios, { AxiosResponse } from "axios";
import config from "../config";

const edbDrupalApiInstance = axios.create({
  baseURL: config.drupal.baseUrl,
  timeout: 30000,
});

const responseBody = (response: AxiosResponse) => response.data

const downloadInstallerRequest = {
  get: (url: string, accessToken: string) => edbDrupalApiInstance.get<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
  }).then(responseBody),
}

const tokenRequest = {
  post: (url: string, formData: FormData) => edbDrupalApiInstance.post<string>(url, formData, {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    }
  }).then(responseBody)
}

export const DrupalApi = {
  getCommunityProduct : (uuid: string, accessToken: string) : Promise<string> => downloadInstallerRequest.get(`/jsonapi/paragraph/download_assets/${uuid}?include=field_supported_os`, accessToken),
  getOAuthToken: (formData: FormData): Promise<any> => tokenRequest.post(`/oauth/token`, formData),
}