import axios, { AxiosResponse } from "axios";
import config from "../config";
import { Cloudsmith } from "../resources/Types";
import { accessToken } from "../resources/OktaSessionData";

const edbApiInstance = axios.create({
  baseURL: config.edbApi.baseUrl,
  timeout: 50000,
});

const responseBody = (response: AxiosResponse) => response.data

const cloudsmithTokenRequest = {
  get: (url: string) => edbApiInstance.get<Cloudsmith>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
  }).then(responseBody),
}

const cloudsmithEDBEmployeeTokenRequest = {
  get: (url: string) => edbApiInstance.get<Cloudsmith>(url, {
      headers: {
        Authorization: `Basic ${config.productRegistry.basicAuth}`
      },
  }).then(responseBody),
}

export const CloudsmithApi = {
  getCloudsmithToken : () : Promise<Cloudsmith[]> => cloudsmithTokenRequest.get('/api/cloudsmith/token/get'),
  getEdbEmployeeCloudsmithToken : () : Promise<Cloudsmith[]> => cloudsmithEDBEmployeeTokenRequest.get('/api/cloudsmith/token/edb-employee/get'),
}