import { configureStore } from '@reduxjs/toolkit';
import productSlice from './productSlice';
import reposAccessSlice from './reposAccessSlice';
import oktaProfileSlice from './oktaProfileSlice';
import operatingSystemsSlice from './operatingSystemsSlice';
import productsByOsSlice from './productsByOsSlice';
import instructionsReplacementSlice from './instructionsReplacementSlice';
import legacyIdsSlice from './legacyIdsSlice';
import legacyInstructionsSlice from './legacyInstructionsSlice';
import redirectURLSlice from './redirectURLSlice';
import authModalSlice from './authModalSlice';
import downloadInstallerSlice from './downloadInstallerSlice';
import productsMetaSlice from './productsMetaSlice';

export default configureStore({
  reducer: {
    products: productSlice,
    reposAccess: reposAccessSlice,
    profile: oktaProfileSlice,
    operatingSystems: operatingSystemsSlice,
    productsByOs: productsByOsSlice,
    instructionsReplacement: instructionsReplacementSlice,
    legacyIds: legacyIdsSlice,
    legacyInstructions: legacyInstructionsSlice,
    redirectURL: redirectURLSlice,
    authModal: authModalSlice,
    downloadInstaller: downloadInstallerSlice,
    productsMeta: productsMetaSlice,
  },
})