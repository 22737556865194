import { createSlice } from "@reduxjs/toolkit";

export const legacyIdsSlice = createSlice({
  name: 'legacyIds',
  initialState: {
    os: '',
    version: '',
    loading: false,
  },
  reducers: {
    legacyIdsRequested: (legacy) => {
      legacy.loading = true;
    },
    legacyOsReceived: (legacy, action) => {
      legacy.os = action.payload;
      legacy.loading = false;
    },
    legacyVersionReceived: (legacy, action) => {
      legacy.version = action.payload;
      legacy.loading = false;
    },
    legacyIdsRequestFailed: (legacy) => {
      legacy.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function.
export const { legacyIdsRequested, legacyOsReceived, legacyVersionReceived, legacyIdsRequestFailed } = legacyIdsSlice.actions;
export default legacyIdsSlice.reducer;