import React, { useRef } from "react";
import ToolTipOverlay from "./ToolTipOverlay";
import { anchorDisplay } from "../utils";

interface LinkIconProps {
  url: string;
  style: {
    position: string;
    left: string;
    top: string;
    paddingLeft: string;
  },
  anchorType: string;
  path: string;
}

const AnchorLink = React.forwardRef<HTMLDivElement, LinkIconProps>((props, ref) => {
  const linkRef = useRef() as React.MutableRefObject<HTMLAnchorElement>;
  const anchorLinkStyles = props.style as React.CSSProperties;

  return (
    <div 
      className="invisible position-relative anchor-link"
      ref={ref}
    >
      <a 
        href={`${props.url}#${anchorDisplay(props.anchorType, props.path)}`}
        ref={linkRef}
        onClick={(e) => {
          e.preventDefault();
          navigator.clipboard.writeText(`${props.url}#${anchorDisplay(props.anchorType, props.path)}`);
        }}
        style={anchorLinkStyles}
      >
        <ToolTipOverlay
          keyId="link-copy"
          placement="top"
          tooltipLabel="Copy Link"
          tooltipClass=""
          component="LinkIcon"
        />
      </a>
    </div>
  )
})

export default AnchorLink;