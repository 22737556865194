//Contains all standard website links
const loginLink = `/accounts/login`;
const registerLink= `/accounts/register`;
const redirectParam = 'fromURI';
const redirectUrl= window.location.href;
const downloadThankYouPageURL = '/thank-you-page'




export { loginLink, registerLink, redirectParam, redirectUrl, downloadThankYouPageURL }