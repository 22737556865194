import React, { useState }from "react";
import Header from "./nav/Header";
import Footer from "./nav/Footer";
import TrustArc from "../TrustArc";
import { hideDropDown } from "../../utils";

interface LayoutProps {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const [Preview, setPreview] = useState(false);
  return (
    <>
      <Header />
      <div id="page-wrapper">
        <TrustArc />
        <main onClick={hideDropDown}>{children}</main>
        <Footer />
      </div>
    </>
  )
}

export default Layout;