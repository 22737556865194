import styles from '../layout/nav/styles.module.scss';

export const Triangle = () => {
  return (
    <div className={styles.triangleContainer}>
      <svg height="30" width="30" className="triangle">
        <polygon points="225,0 215,200 235,200" style={{ fill: "#e7c56b", stroke: "#e7c56b", strokeWidth: "1", transform: "scale(0.1)" }} />
        <g>
          <g>
            <rect y="130" x="100" style={{fill:"#FFF", width:"px", height:"1px", transform: "scale(0.1)" }} />
          </g>
        </g>
      </svg>
    </div>
  )
}