import { useRef } from "react";
import ButtonEDB from "../ButtonEdb";
import "../../css/repo-access-button.css";

interface AccessReposProps {
  version: string;
  requestAccess: boolean;
}

const AccessReposBtn = ( props: AccessReposProps ) => {
  const requestAccessRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  let link = "/repos";
  let label = `Access EDB Repos ${props.version}.0`
  if (props.version === "1") {
    link = "/repos/legacy";
  }

  let requestAccess = false;
  let spinner = false;

  if (props.requestAccess) {
    if (props.version === "2") {
      label = 'Request Access';
      requestAccess = true;
      spinner = true;
    }
  }

  return (
    <span className="repo-align-end">
      <ButtonEDB 
        label={label} 
        href={link} 
        requestAccess={requestAccess} 
        ref={requestAccessRef} 
        spinner={spinner} 
        color='teal'
      />
    </span>
  )
}

export default AccessReposBtn;