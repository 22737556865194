import { createSlice } from "@reduxjs/toolkit";

export const productsMetaSlice = createSlice({
  name: 'productsMeta',
  initialState: {
    list: {},
    loading: false,
  },
  reducers: {
    productsMetaRequested: (productsMeta) => {
      productsMeta.loading = true;
    },
    productsMetaReceived: (productsMeta, action) => {
      productsMeta.list = action.payload;
      productsMeta.loading = false;
    },
    productsMetaRequestFailed: (productsMeta) => {
      productsMeta.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function.
export const { productsMetaRequested, productsMetaReceived, productsMetaRequestFailed } = productsMetaSlice.actions;
export default productsMetaSlice.reducer;